import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RennovaService } from '@app/shared/services/rennova.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ProductRennova } from './../../models/product-rennova';

@Component({
  selector: 'app-modal-add-product-rennova',
  templateUrl: './modal-add-product-rennova.component.html',
  styleUrls: ['./modal-add-product-rennova.component.scss']
})
export class ModalAddProductRennovaComponent implements OnInit {
  products: ProductRennova[] = [];
  loading = false;
  hasError = false;

  @Output() selectEvent = new EventEmitter<ProductRennova>();

  constructor(
    public modalRef: BsModalRef,
    private rennovaService: RennovaService,
    private notification: NzNotificationService
  ) {}

  ngOnInit(): void {
    this.findAllProducts();
  }
  findAllProducts() {
    this.loading = true;
    this.rennovaService.findAll().subscribe(
      products => {
        this.products = products;
      },
      () => {
        this.notification.error('Ocorreu um erro ao buscar produtos da rennova.', '');
        this.modalRef.hide();
      },
      () => {
        this.loading = false;
      }
    );
  }

  activeChange(product: ProductRennova) {
    product.active = !product.active;
  }

  onSelect(product: ProductRennova) {
    this.selectEvent.emit(product);
    this.modalRef.hide();
  }
}
