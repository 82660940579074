import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

interface CfmConsultResponse {
  name: string;
  crm: string;
  uf: string;
  specialty: string;
  updateDate: string;
  inscription: {
    code: string;
    description: string;
  };
  situation: {
    code: string;
    status: string;
    description: string;
    explanation?: string;
  };
  errorCode?: number;
  errorMessage?: string;
}

interface CfmValidateResponse {
  inscription: {
    code: string;
    description: string;
  };
  situation: {
    code: string;
    status: string;
    description: string;
    explanation?: string;
  };
  errorCode?: number;
  errorMessage?: string;
}

@Injectable({
  providedIn: 'root'
})
export class CfmService {
  constructor(private http: HttpClient) {}

  consult(crm: string, uf: string): Promise<CfmConsultResponse> {
    return this.http
      .get<CfmConsultResponse>(`${environment.apiRoot}cfm/consult`, { params: { crm, uf } })
      .toPromise();
  }

  validate(crm: string, uf: string, cpf: string, birthdate: string): Promise<CfmValidateResponse> {
    return this.http
      .get<CfmValidateResponse>(`${environment.apiRoot}cfm/validate`, {
        params: { crm, uf, cpf, birthdate }
      })
      .toPromise();
  }
}
