import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PetPatientService } from '@app/modules/patients/services/pet-patient.service';
import { Furs } from '@app/shared/data/Fur';
import { Fur } from '@app/shared/models';
import { Race } from '@app/shared/models/races';
import { Species } from '@app/shared/models/species';
import moment from 'moment';

@Component({
  selector: 'app-pet-data-form',
  templateUrl: './pet-data-form.component.html',
  styleUrls: ['./pet-data-form.component.scss']
})
export class PetDataFormComponent implements OnInit {
  @Input() form: FormGroup;

  species: Species[];
  races: Race[];
  furs: Fur[] = Furs;

  constructor(private petPatientService: PetPatientService) {}

  async ngOnInit() {
    this.species = await this.petPatientService.getSpecies().toPromise();
  }

  get cpf() {
    return this.form.get('responsible.cpf');
  }

  get currentDate() {
    return moment().format('YYYY-MM-DD');
  }

  async setSpecies(title: any) {
    const species = this.species.find(s => s.title === title);
    await this.getRaces(species._id);
  }

  async getRaces(speciesId: string) {
    this.form.get('race').setValue('');
    this.races = await this.petPatientService.getRaces(speciesId).toPromise();
  }

  changeApproximateAge(months: string) {
    if (months) {
      const years = +months > 0 ? +months : 0;
      const dateOfBirth = moment()
        .subtract(years, 'month')
        .format('YYYY-MM-DD');
      this.form.get('dateOfBirth').setValue(dateOfBirth);
    }
  }
}
