import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PetPatient } from '@app/shared/models/pet-patient';
import { Race } from '@app/shared/models/races';
import { Species } from '@app/shared/models/species';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

export const PET_PATIENT_URL = 'pets';

@Injectable({
  providedIn: 'root'
})
export class PetPatientService {
  constructor(private http: HttpClient) {}

  getPetsByResponsibleCpf(cpf: string): Observable<PetPatient[]> {
    return this.http.get<PetPatient[]>(`${environment.apiRoot}${PET_PATIENT_URL}`, {
      params: { cpf }
    });
  }

  getSpecies(): Observable<Species[]> {
    return this.http.get<Species[]>(`${environment.apiRoot}${PET_PATIENT_URL}/species`);
  }

  getRaces(speciesId: string): Observable<Race[]> {
    return this.http.get<Race[]>(`${environment.apiRoot}${PET_PATIENT_URL}/${speciesId}/races`);
  }

  save(patient: PetPatient): Observable<PetPatient> {
    return this.http.post<PetPatient>(`${environment.apiRoot}${PET_PATIENT_URL}`, patient);
  }

  getPetById(id: string): Observable<PetPatient> {
    return this.http.get<PetPatient>(`${environment.apiRoot}${PET_PATIENT_URL}/${id}`);
  }

  update(id: string, data: any): Observable<PetPatient> {
    return this.http.put<PetPatient>(`${environment.apiRoot}${PET_PATIENT_URL}/${id}`, data);
  }
}
