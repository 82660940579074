import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';
import { ComponentsModule } from '@app/shared/components/components.module';
import { ModalSendDocumentComponent } from '@app/shared/components/modal-send-document/modal-send-document.component';
import { NzCardModule, NzDropDownModule } from 'ng-zorro-antd';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxMaskModule } from 'ngx-mask';
import { PatientsModule } from '../patients/patients.module';
import { DocumentRoutingModule } from './document-routing.module';
import { DocumentComponent } from './document.component';
import { DocumentGuard } from './guards/document.guard';
import { AttestationComponent } from './pages/attestation/attestation.component';
import { ConfirmationComponent } from './pages/confirmation/confirmation.component';
import { ExamComponent } from './pages/exam/exam.component';
import { InfusionProtocolComponent } from './pages/infusion-protocol/infusion-protocol.component';
import { ListingComponent } from './pages/listing/listing.component';
import { ModalIframeComponent } from './pages/modal-iframe/modal-iframe.component';
import { ModalListVictaProtocolComponent } from './pages/modal-list-victa-protocol/modal-list-victa-protocol.component';
import { ModalSelectLayoutComponent } from './pages/modal-select-layout/modal-select-layout.component';
import { ModelAddFavoriteDocumentComponent } from './pages/model-add-favorite-document/model-add-favorite-document.component';
import { ModelListFavoriteDocumentComponent } from './pages/model-list-favorite-document/model-list-favorite-document.component';
import { OrientationComponent } from './pages/orientation/orientation.component';
import { NavigationComponent } from './pages/prescription/navigation.component';
import { PrescriptionComponent } from './pages/prescription/prescription.component';
import { UnsignedPrescriptionModalComponent } from './pages/prescription/unsigned-prescription-modal.component';
import { UploadPdfComponent } from './pages/prescription/upload-pdf.component';
import { RetentionConfirmationComponent } from './pages/retention-confirmation/retention-confirmation.component';
import { ModalSucessSendDocumentComponent } from '@app/shared/components/modal-sucess-send-document/modal-sucess-send-document.component';

@NgModule({
  declarations: [
    PrescriptionComponent,
    InfusionProtocolComponent,
    NavigationComponent,
    ConfirmationComponent,
    UnsignedPrescriptionModalComponent,
    RetentionConfirmationComponent,
    ListingComponent,
    UploadPdfComponent,
    AttestationComponent,
    ExamComponent,
    DocumentComponent,
    ModalIframeComponent,
    OrientationComponent,
    ModelAddFavoriteDocumentComponent,
    ModelListFavoriteDocumentComponent,
    ModalSelectLayoutComponent,
    ModalListVictaProtocolComponent
  ],
  exports: [
    PrescriptionComponent,
    InfusionProtocolComponent,
    NavigationComponent,
    ConfirmationComponent,
    UnsignedPrescriptionModalComponent,
    RetentionConfirmationComponent,
    ListingComponent,
    UploadPdfComponent,
    AttestationComponent,
    ExamComponent,
    DocumentComponent,
    ModalIframeComponent,
    OrientationComponent,
    ModelAddFavoriteDocumentComponent,
    ModelListFavoriteDocumentComponent,
    ModalSelectLayoutComponent,
    ModalListVictaProtocolComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DocumentRoutingModule,
    PatientsModule,
    ComponentsModule,
    NgxLoadingModule.forRoot({}),
    NzDropDownModule,
    NzCardModule,
    NzCarouselModule,
    NzIconModule,
    NgxMaskModule.forRoot(),
    NzPopoverModule,
    ModalModule.forRoot(),
    NzProgressModule,
    NzUploadModule
  ],
  providers: [DocumentGuard],
  entryComponents: [
    ModalIframeComponent,
    ModelAddFavoriteDocumentComponent,
    ModelListFavoriteDocumentComponent,
    ModalSendDocumentComponent,
    ModalSucessSendDocumentComponent,
    ModalSelectLayoutComponent,
    ModalListVictaProtocolComponent
  ]
})
export class DocumentModule {}
