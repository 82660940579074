<form nz-form [formGroup]="form" theme>
  <div class="row justify-content-between">
    <nz-alert
      *ngIf="!patient || patient.status === 1"
      class="mb-4"
      nzType="warning"
      nzMessage="Para agilizar seu cadastro, buscaremos dados complementares no site da Receita Federal.
Este é um procedimento seguro e o sigilo dos seus dados é garantido pela nossa plataforma"
    ></nz-alert>
    <div class="col-md-6">
      <div class="row align-items-center">
        <div class="col-md">
          <nz-form-item>
            <nz-form-label nzFor="cpf">CPF <span class="mandatory">*</span></nz-form-label>
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                id="cpf"
                formControlName="cpf"
                placeholder="000.000.000-00"
                mask="000.000.000-00"
              />
              <nz-form-explain *ngIf="cpfCtrl.dirty && cpfCtrl.errors">
                <ng-container *ngIf="cpfCtrl.hasError('required')">
                  Campo obrigatório, por favor digite seu CPF.
                </ng-container>
                <ng-container *ngIf="!cpfCtrl.hasError('required') && cpfCtrl.hasError('cpf')">
                  CPF inválido, por favor digite um CPF válido.
                </ng-container>
                <ng-container *ngIf="cpfCtrl.hasError('alreadyExists')">
                  CPF já cadastrado.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-md">
          <nz-form-item>
            <nz-form-label nzFor="birthdate"
              >Data de nascimento<span class="mandatory"> *</span></nz-form-label
            >
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                id="birthdate"
                type="tel"
                mask="00/00/0000"
                formControlName="birthdate"
                placeholder="DD/MM/AAAA"
              />
              <nz-form-explain *ngIf="dateCtrl.dirty && dateCtrl.errors">
                <ng-container *ngIf="dateCtrl.hasError('required')">
                  Campo obrigatório, por favor digite sua data de nascimento.
                </ng-container>
                <ng-container *ngIf="!dateCtrl.hasError('required') && dateCtrl.hasError('birthdate')">
                  Nome inválido.
                </ng-container>
                <ng-container *ngIf="dateCtrl.hasError('invalidDate') && dateCtrl.value.length > 0">
                  Data inválida.
                </ng-container>
                <ng-container *ngIf="dateCtrl.hasError('nonMatchingCpf')">
                  Data de nascimento não corresponde ao CPF informado.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-auto pt-3" *ngIf="isRegisteredByPatient">
          <button
            nz-button
            class="btn-primary"
            (click)="consultCpf()"
            [disabled]="cpfCtrl.invalid || dateCtrl.invalid"
          >
            OK
          </button>
        </div>

        <ng-container *ngIf="verifiedCpf">
          <div class="col-12">
            <nz-form-item>
              <nz-form-label nzFor="fullname"
                >Nome completo <span class="mandatory">*</span></nz-form-label
              >
              <nz-form-control nzHasFeedback>
                <input
                  formControlName="fullname"
                  id="fullname"
                  nz-input
                  placeholder="ex: João Gilberto"
                  maxlength="128"
                  autocomplete="disabled"
                />
                <nz-form-explain *ngIf="form.get('fullname').dirty && form.get('fullname').errors">
                  <ng-container *ngIf="form.get('fullname').hasError('required')">
                    Campo obrigatório, por favor digite o seu nome e sobrenome.
                  </ng-container>
                  <ng-container
                    *ngIf="
                      !form.get('fullname').hasError('required') &&
                      form.get('fullname').hasError('fullname')
                    "
                  >
                    Por favor digite o seu nome e sobrenome.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-12">
            <nz-form-item>
              <nz-form-label nzFor="name"
                >Nome de exibição/social<span class="mandatory">*</span></nz-form-label
              >
              <nz-form-control nzHasFeedback>
                <input
                  formControlName="name"
                  id="name"
                  nz-input
                  placeholder="ex: João Gilberto"
                  maxlength="128"
                  autocomplete="disabled"
                />
                <nz-form-explain *ngIf="form.get('name').dirty && form.get('name').errors">
                  <ng-container *ngIf="form.get('name').hasError('required')">
                    Campo obrigatório, por favor digite um nome de exibição.
                  </ng-container>
                  <ng-container
                    *ngIf="!form.get('name').hasError('required') && form.get('name').hasError('name')"
                  >
                    Por favor digite um nome de exibição.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>

          <ng-container *ngIf="isRegisteredByPatient">
            <div class="col-6">
              <nz-form-item>
                <nz-form-label nzFor="identity"
                  >Identidade <span class="mandatory">*</span></nz-form-label
                >
                <nz-form-control nzHasFeedback>
                  <input
                    nz-input
                    id="identity"
                    formControlName="identity"
                    placeholder="ex: 18.040.818-5"
                    maxlength="12"
                  />
                  <nz-form-explain *ngIf="form.get('identity').dirty && form.get('identity').errors">
                    <ng-container *ngIf="form.get('identity').hasError('required')">
                      Campo obrigatório, por favor digite um documento de identidade.
                    </ng-container>
                  </nz-form-explain>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div class="col-6">
              <nz-form-item>
                <nz-form-label nzFor="sex">Sexo <span class="mandatory">*</span></nz-form-label>
                <nz-form-control nzHasFeedback>
                  <select formControlName="sex" id="sex" nz-input nzPlaceHolder="Selecione">
                    <option value="M">Masculino</option>
                    <option value="F">Feminino</option>
                  </select>
                  <nz-form-explain *ngIf="form.get('sex').dirty && form.get('sex').errors">
                    <ng-container *ngIf="form.get('sex').hasError('required')">
                      Campo obrigatório, por favor selecione o sexo.
                    </ng-container>
                  </nz-form-explain>
                </nz-form-control>
              </nz-form-item>
            </div>
          </ng-container>

          <div class="col-12" [formGroup]="form.get('membership')">
            <nz-form-item>
              <nz-form-label nzFor="mothersName">Nome da mãe</nz-form-label>
              <nz-form-control nzHasFeedback>
                <input
                  nz-input
                  placeholder="Digite o nome da mãe"
                  formControlName="mothersName"
                  id="mothersName"
                  maxlength="128"
                  autocomplete="disabled"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </ng-container>
      </div>
      <div>
        <label *ngIf="!verifiedCpf" nz-checkbox class="mb-2" formControlName="noCpf"
          >Menor sem CPF?</label
        >
      </div>

      <div>
        <label *ngIf="!verifiedCpf" nz-checkbox class="mb-2" formControlName="noCpf" [(ngModel)]="isPet"
          >Paciente pet?</label
        >
      </div>

      <div *ngIf="form.value.noCpf">
        <span
          >Digite acima o CPF e data de nascimento do(a) <span class="mandatory">responsável</span></span
        >
      </div>
    </div>

    <ng-container *ngIf="verifiedCpf">
      <div class="col-md-5" [@slideInOut]>
        <nz-form-item *ngIf="form.get('cellphone') as cellphone">
          <nz-form-label nzFor="cellphone"
            >Telefone 1 <span class="mandatory">*</span>
            <app-popover-button
              content="Será utilizado para receber suas receitas e demais documentos via WhatsApp e SMS"
            ></app-popover-button>
          </nz-form-label>
          <nz-form-control nzHasFeedback>
            <input
              nz-input
              id="cellphone"
              [mask]="cellphone.value.length <= 10 ? '(00) 0000-00009' : '(00) 00000-0000'"
              placeholder="(21) 92222-2222"
              formControlName="cellphone"
              autocomplete="disabled"
            />
            <nz-form-explain *ngIf="cellphone.dirty && cellphone.errors">
              <ng-container *ngIf="cellphone.hasError('required')">
                Campo obrigatório, por favor digite seu telefone para contato.
              </ng-container>
            </nz-form-explain>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="form.get('telephone') as telephone">
          <nz-form-label nzFor="telephone" class="cellphone">Telefone 2</nz-form-label>
          <nz-form-control>
            <input
              nz-input
              id="telephone"
              [mask]="telephone.value.length <= 10 ? '(00) 0000-00009' : '(00) 00000-0000'"
              formControlName="telephone"
              placeholder="(21) 2222-2222"
              autocomplete="disabled"
            />
            <nz-form-explain *ngIf="telephone.dirty && telephone.errors">
              <ng-container *ngIf="telephone.hasError('telephone')">
                Telefone inválido, por favor digite um número válido.
              </ng-container>
            </nz-form-explain>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <div class="row">
            <div class="col-5">
              <nz-form-label nzFor="email">E-mail <span class="mandatory">*</span></nz-form-label>
            </div>
            <div class="col">
              <label
                *ngIf="!isRegisteredByPatient"
                nz-checkbox
                (nzCheckedChange)="checkEmail($event)"
                style="font-size: 14px;"
                >Não cadastrar e-mail</label
              >
            </div>
          </div>
          <nz-form-control nzHasFeedback>
            <input
              nz-input
              id="email"
              type="email"
              formControlName="email"
              placeholder="usuario@email.com"
              style="text-transform: lowercase;"
              (keydown.space)="$event.preventDefault()"
              autocomplete="disabled"
            />

            <nz-form-explain>
              <ng-container *ngIf="form.get('email').hasError('required') && form.get('email').dirty">
                <span class="error">Campo obrigatório, por favor digite seu e-mail.</span><br />
              </ng-container>
              <ng-container *ngIf="form.get('email').invalid && form.get('email').value?.length > 0">
                <span
                  class="error"
                  *ngIf="form.get('email').hasError('alreadyExists'); else invalidEmail"
                  >E-mail já cadastrado, por favor escolha outro e-mail.</span
                >
                <ng-template #invalidEmail>
                  <span class="error">E-mail inválido, por favor digite um e-mail válido.</span>
                </ng-template>
                <br />
              </ng-container>
            </nz-form-explain>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="isRegisteredByPatient">
          <nz-form-label nzFor="emailConfirmation"
            >Confirmação de e-mail<span class="mandatory"> *</span>
          </nz-form-label>
          <nz-form-control nzHasFeedback>
            <input
              nz-input
              id="emailConfirmation"
              formControlName="emailConfirmation"
              placeholder="usuario@email.com"
              type="email"
              style="text-transform: lowercase;"
              (keydown.space)="$event.preventDefault()"
              onpaste="false"
              autocomplete="disabled"
            />

            <nz-form-explain class="error">
              <ng-container
                *ngIf="
                  form.get('emailConfirmation').hasError('required') &&
                  form.get('emailConfirmation').dirty
                "
              >
                <span> Campo obrigatório, por favor digite seu e-mail.</span><br />
              </ng-container>

              <ng-container *ngIf="form.get('emailConfirmation').hasError('emailMatch')">
                <span>Os endereços de e-mail não correspondem</span><br />
              </ng-container>

              <ng-container
                *ngIf="
                  !form.hasError('emailMatch') &&
                  form.get('emailConfirmation').invalid &&
                  form.get('emailConfirmation').value.length > 0
                "
              >
                <span>E-mail inválido, por favor digite um e-mail válido.</span><br />
              </ng-container>
            </nz-form-explain>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="!isRegisteredByPatient">
          <nz-form-label nzFor="sex">Sexo <span class="mandatory">*</span></nz-form-label>
          <nz-form-control nzHasFeedback>
            <select formControlName="sex" id="sex" nz-input nzPlaceHolder="Selecione">
              <option value="M">Masculino</option>
              <option value="F">Feminino</option>
            </select>
            <nz-form-explain *ngIf="form.get('sex').dirty && form.get('sex').errors">
              <ng-container *ngIf="form.get('sex').hasError('required')">
                Campo obrigatório, por favor selecione o sexo.
              </ng-container>
            </nz-form-explain>
          </nz-form-control>
        </nz-form-item>
      </div>

      <nz-divider nzOrientation="left" nzText="Endereço"></nz-divider>

      <div class="col-md-12" [@slideInOut] *ngIf="form.get('address')">
        <div class="row" [formGroup]="form.get('address')">
          <div class="col-md-3">
            <nz-form-item>
              <nz-form-label nzFor="cep">CEP<span class="mandatory"> *</span></nz-form-label>
              <nz-form-control nzHasFeedback>
                <input
                  nz-input
                  id="cep"
                  formControlName="cep"
                  placeholder="00.000-000"
                  mask="00.000-000"
                  autocomplete="disabled"
                  (keyup)="onKey($event)"
                />
                <nz-form-explain *ngIf="form.get('address.cep').dirty && form.get('address.cep').errors">
                  <ng-container *ngIf="form.get('address.cep').hasError('required')">
                    Campo obrigatório, por favor digite seu CEP.
                  </ng-container>
                  <ng-container
                    *ngIf="
                      !form.get('address.cep').hasError('required') &&
                      form.get('address.cep').hasError('address.cep')
                    "
                  >
                    CEP inválido, por favor digite um CEP válido.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-md-6">
            <nz-form-item>
              <nz-form-label nzFor="logradouro"
                >Logradouro<span class="mandatory"> *</span></nz-form-label
              >
              <nz-form-control nzHasFeedback>
                <input
                  formControlName="street"
                  id="street"
                  nz-input
                  placeholder="Digite o logradouro"
                  maxlength="128"
                  autocomplete="disabled"
                />
                <nz-form-explain
                  *ngIf="form.get('address.street').dirty && form.get('address.street').errors"
                >
                  <ng-container *ngIf="form.get('address.street').hasError('required')">
                    Campo obrigatório, por favor digite o logradouro.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-12 col-md-3">
            <nz-form-item>
              <nz-form-label nzFor="number">Número<span class="mandatory"> *</span></nz-form-label>
              <nz-form-control nzHasFeedback>
                <input
                  formControlName="number"
                  id="number"
                  nz-input
                  placeholder="Digite o número"
                  maxlength="10"
                  autocomplete="disabled"
                />
                <nz-form-explain
                  *ngIf="form.get('address.number').dirty && form.get('address.number').errors"
                >
                  <ng-container *ngIf="form.get('address.number').hasError('required')">
                    Campo obrigatório, por favor digite o número.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-12 col-md-3">
            <nz-form-item>
              <nz-form-label nzFor="complement">Complemento</nz-form-label>
              <nz-form-control nzHasFeedback>
                <input
                  formControlName="complement"
                  id="complement"
                  nz-input
                  placeholder="Digite o complemento"
                  maxlength="128"
                  autocomplete="disabled"
                />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-12 col-md-3">
            <nz-form-item>
              <nz-form-label nzFor="neighborhood">Bairro<span class="mandatory"> *</span></nz-form-label>
              <nz-form-control nzHasFeedback>
                <input
                  formControlName="neighborhood"
                  id="neighborhood"
                  nz-input
                  placeholder="Digite o bairro"
                  maxlength="128"
                  autocomplete="disabled"
                />
                <nz-form-explain
                  *ngIf="
                    form.get('address.neighborhood').dirty && form.get('address.neighborhood').errors
                  "
                >
                  <ng-container *ngIf="form.get('address.neighborhood').hasError('required')">
                    Campo obrigatório, por favor digite o bairro.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-12 col-md-4">
            <nz-form-item>
              <nz-form-label nzFor="city">Cidade<span class="mandatory"> *</span></nz-form-label>
              <nz-form-control nzHasFeedback>
                <input
                  formControlName="city"
                  id="city"
                  nz-input
                  placeholder="Digite a cidade"
                  maxlength="64"
                  autocomplete="disabled"
                />
                <nz-form-explain
                  *ngIf="form.get('address.city').dirty && form.get('address.city').errors"
                >
                  <ng-container *ngIf="form.get('address.city').hasError('required')">
                    Campo obrigatório, por favor digite a cidade.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-12 col-md-2">
            <nz-form-item>
              <nz-form-label nzFor="uf">UF<span class="mandatory"> *</span></nz-form-label>
              <nz-form-control nzHasFeedback>
                <select
                  *ngIf="!isRegisteredByPatient"
                  id="uf"
                  formControlName="uf"
                  nz-input
                  nzAllowClear
                  nzShowSearch
                  nzPlaceHolder="Selecione"
                >
                  <option *ngFor="let state of states" [value]="state?.sigla">{{ state?.sigla }}</option>
                </select>
                <nz-select
                  *ngIf="isRegisteredByPatient"
                  formControlName="uf"
                  id="uf"
                  nzAllowClear
                  nzShowSearch
                  nzPlaceHolder="Selecione"
                >
                  <nz-option
                    *ngFor="let state of states"
                    [nzLabel]="state?.sigla"
                    [nzValue]="state?.sigla"
                  ></nz-option>
                </nz-select>
                <nz-form-explain *ngIf="form.get('address.uf').dirty && form.get('address.uf').errors">
                  <ng-container *ngIf="form.get('address.uf').hasError('required')">
                    Campo obrigatório, por favor selecione o UF.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</form>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
