import moment from 'moment';

const AGE_TEENAGER = 13;
const AGE_ADULT = 18;

export class DateUtils {
  static isUnderAge(dateOfBirth: Date | string, format = null) {
    const date = dateOfBirth instanceof Date ? dateOfBirth : moment(dateOfBirth, format);
    const age = moment().diff(date, 'years', false);
    return age < AGE_ADULT;
  }
}
