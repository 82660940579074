import { Fur } from '@app/shared/models';

export const Furs: Fur[] = [
  { title: 'abricot' },
  { title: 'amarelo' },
  { title: 'arlequim' },
  { title: 'azul aço' },
  { title: 'azul roão' },
  { title: 'bege' },
  { title: 'black and tan' },
  { title: 'boston' },
  { title: 'branca' },
  { title: 'branco e bege' },
  { title: 'branco e cinza' },
  { title: 'branco e marron' },
  { title: 'branco e preto' },
  { title: 'branco e vermelho' },
  { title: 'bronze' },
  { title: 'caramelo' },
  { title: 'champagne' },
  { title: 'cinza' },
  { title: 'dourado' },
  { title: 'fulvo' },
  { title: 'indefinida' },
  { title: 'manto negro' },
  { title: 'marrom' },
  { title: 'pelo duro (arame)' },
  { title: 'pintado' },
  { title: 'preta' },
  { title: 'preta e amarelo' },
  { title: 'preto e vermelho' },
  { title: 'preto, branco e marrom' },
  { title: 'sal e pimenta' },
  { title: 'tigrado' },
  { title: 'tricolor' },
  { title: 'vermelho' },
  { title: 'vermelho e branco' }
];
