import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '@app/core/services/validation.service';
import { SpecialtiesService } from '@app/modules/admin/listing/features-listing/specialties.service';
import { HealthProfessionalService } from '@app/modules/health-professional/services/health-professional.service';
import { PatientsService } from '@app/modules/patients/services/patients.service';
import { PharmacistService } from '@app/modules/pharmacist/services/pharmacist.service';
import { PharmacyService } from '@app/modules/pharmacy/services/pharmacy.service';
import { UserService } from '@app/modules/user/user.service';
import { BrazilianStates } from '@app/shared/data/Brazilian-states';
import { BrazilState, Specialty } from '@app/shared/models';
import { User } from '@app/shared/models/decodedLoginToken';
import { ThemeService } from '@app/theme';
import { markFormGroup } from '@app/utils/markFormGroup';
import { removeFormGroupValidators } from '@app/utils/removeFormGroupValidators';
import { environment } from '@env/environment';
import * as moment from 'moment';
import { NzModalRef, NzNotificationService } from 'ng-zorro-antd';
import { of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-modal-edit-personal-data',
  templateUrl: './modal-edit-personal-data.component.html',
  styleUrls: ['./modal-edit-personal-data.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 0 })),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        group([animate(300, style({ height: 0 })), animate('400ms ease-in-out', style({ opacity: 0 }))])
      ]),
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        group([
          animate(300, style({ height: '*' })),
          animate('400ms ease-in-out', style({ opacity: 1 }))
        ])
      ])
    ])
  ]
})
export class ModalEditPersonalDataComponent implements OnInit {
  @Input() user: User;
  @Input() completeUser: any;
  @Input() userTypeLogged: string;
  @Input() touched = false;
  form: FormGroup;
  patientForm: FormGroup;
  pharmacyForm: FormGroup;
  loading = false;
  states: BrazilState[] = BrazilianStates;
  specialtiesOptions: Specialty[] = [];
  selectedSpecialties: string[] = [];
  currentDate = new Date();
  isMobile = false;

  @Output() updateUserEvent = new EventEmitter<User>();

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private healthProfessionalService: HealthProfessionalService,
    private pharmacyService: PharmacyService,
    private pharmacistService: PharmacistService,
    private patientService: PatientsService,
    private nzModalRef: NzModalRef,
    private specialtyService: SpecialtiesService,
    private notification: NzNotificationService,
    private themeService: ThemeService
  ) {}

  ngOnInit() {
    if (this.user.userType === 'patient') {
      this.buildPatientForm();
    } else if (this.user.userType === 'healthProfessional') {
      this.getSpecialties();
      this.buildHealthProfessionalForm();
    } else if (this.user.userType === 'pharmacist') {
      this.buildPharmacistForm();
    } else {
      this.buildPharmacyForm();
    }

    if (this.isAdmin) {
      removeFormGroupValidators(this.form);
    } else {
      this.disableFields();
    }

    if (this.emailForm) {
      this.emailForm.valueChanges
        .pipe(
          map(email => {
            const value = email && email.toLowerCase();
            this.emailForm.setValue(value, { emitEvent: false });
            return value;
          }),
          filter(_ => this.emailForm.valid),
          debounceTime(environment.debounceTime),
          switchMap(email =>
            this.userService
              .checkEmailExists(email, this.completeUser.tenantId)
              .pipe(catchError(err => of(err)))
          )
        )
        .subscribe(res => {
          if (res.status === 200) {
            const exists = this.user.email !== res.email;
            this.emailForm.setErrors(exists ? { exists } : null);
          } else if (res.status === 404) {
            this.emailForm.setErrors(null);
          } else if (res.status === 400) {
            this.emailForm.setErrors({ invalid: true });
          }
        });
    }
    this.getScreenSize();

    if (this.touched) {
      markFormGroup(this.form);
    }
  }

  @HostListener('window:resize', ['$event'])
  private getScreenSize(event?) {
    const { innerWidth: width, innerHeight: height } = window;
    this.isMobile = width < 1025;
  }

  get isAdmin() {
    return this.userTypeLogged === 'admin';
  }

  get emailForm() {
    return this.form.get('email');
  }

  get isDentist() {
    return this.completeUser.professionalType === 'dentist';
  }

  get isVeterinarian(): boolean {
    return this.completeUser.professionalType === 'veterinarian';
  }

  get specialtiesForm() {
    return <FormArray>this.form.get('specialties');
  }

  private disableFields() {
    if (this.form.get('cpf') && this.form.value.cpf) {
      this.form.get('cpf').disable();
    }

    if (this.form.get('dateOfBirth') && this.form.value.dateOfBirth) {
      this.form.get('dateOfBirth').disable();
    }

    if (this.form.get('cnpj')) {
      this.form.get('cnpj').disable();
    }

    if (this.themeService.getActiveTheme().name === 'theme-mrd') {
      this.form.get('email').disable();
    }
  }

  get popoverContent() {
    return this.user.userType === 'patient'
      ? 'Será utilizado para receber suas receitas e demais documentos via WhatsApp e SMS'
      : 'Será exibido no cabeçalho dos documentos emitidos na plataforma';
  }

  private getSpecialties() {
    this.specialtyService
      .getSpecialtiesByType(this.completeUser.professionalType)
      .subscribe(specialties => {
        specialties.forEach(function(item, i) {
          if (item.code === 1) {
            specialties.splice(i, 1);
            specialties.unshift(item);
          }
        });
        this.specialtiesOptions = specialties;
      });
  }

  private buildPatientForm() {
    const dateOfBirth =
      this.completeUser.dateOfBirth && this.getDateOfBirth(new Date(this.completeUser.dateOfBirth));

    const email = this.completeUser.user ? this.completeUser.user.email : this.user.email;
    const emailContact = this.completeUser.user
      ? this.completeUser.user.emailContact || this.completeUser.user.email
      : this.user.email;

    this.form = this.fb.group({
      tenantId: [this.completeUser.tenantId],
      fullname: [this.completeUser.fullname || this.completeUser.name, Validators.required],
      name: [this.completeUser.name, Validators.required],
      cellphone: [this.completeUser.cellphone, Validators.required],
      telephone: [this.completeUser.telephone],
      identity: [this.completeUser.identity],
      sex: [this.completeUser.sex, Validators.required],
      cpf: [this.completeUser.cpf, ValidationService.cpfValidator],
      email: [email, Validators.email],
      emailContact: [emailContact, Validators.email],
      dateOfBirth: [dateOfBirth, Validators.required]
    });

    if (this.user.userType === this.userTypeLogged) {
      this.form.get('identity').setValidators(Validators.required);
      this.form.get('email').setValidators(Validators.required);
    }
  }

  private buildPharmacistForm() {
    const dateOfBirth = this.getDateOfBirth(this.completeUser.dateOfBirth);
    const email = this.completeUser.user ? this.completeUser.user.email : this.user.email;
    const emailContact = this.completeUser.user
      ? this.completeUser.user.emailContact || this.completeUser.user.email
      : this.user.email;

    this.form = this.fb.group({
      name: [this.completeUser.name, Validators.required],
      cellphone: [this.completeUser.cellphone],
      telephone: [this.completeUser.telephone],
      identity: [this.completeUser.identity, Validators.required],
      cpf: [this.completeUser.cpf],
      email: [email, Validators.email],
      emailContact: [emailContact, Validators.email],
      dateOfBirth: [dateOfBirth, Validators.required]
    });
  }

  private buildPharmacyForm() {
    const email = this.completeUser.user ? this.completeUser.user.email : this.user.email;
    this.form = this.fb.group({
      telephone: [this.completeUser.telephone, Validators.required],
      cellphone: [this.completeUser.cellphone],
      fantasyName: [this.completeUser.fantasyName],
      cnpj: [this.completeUser.cnpj, Validators.required],
      email: [email, Validators.email]
    });
  }

  private buildHealthProfessionalForm() {
    const dateOfBirth =
      this.completeUser.dateOfBirth && this.getDateOfBirth(this.completeUser.dateOfBirth);

    const completeUser = this.completeUser;
    const email = completeUser.user ? completeUser.user.email : this.user.email;
    const emailContact = this.completeUser.user
      ? this.completeUser.user.emailContact || this.completeUser.user.email
      : this.user.email;

    this.form = this.fb.group({
      tenantId: [this.completeUser.tenantId],
      name: [completeUser.name, Validators.required],
      sex: [completeUser.sex, Validators.required],
      professionalType: [completeUser.professionalType],
      cellphone: [completeUser.cellphone, Validators.required],
      telephone: [completeUser.telephone],
      regionalCouncilNumber: this.fb.group({
        number: [completeUser.regionalCouncilNumber.number, Validators.required],
        uf: [completeUser.regionalCouncilNumber.uf, Validators.required],
        label: [completeUser.regionalCouncilNumber.label, Validators.required]
      }),
      cpf: [completeUser.cpf, Validators.required],
      specialties: this.fb.array([], Validators.required),
      email: [email, [Validators.required, Validators.email]],
      emailContact: [emailContact, [Validators.required, Validators.email]],
      dateOfBirth: [dateOfBirth, Validators.required]
    });

    if (this.isVeterinarian)
      this.form.setControl('mapaSipeagro', new FormControl(this.completeUser.mapaSipeagro));

    completeUser.specialties.forEach(specialty => {
      const formGroup = this.buildSpecialtyForm(specialty);
      this.specialtiesForm.push(formGroup);
      if (!this.isVeterinarian) {
        const rqeCtrl = <FormGroup>formGroup.controls.rqe;
        if (specialty.notExists !== undefined) {
          this.checkRQE(rqeCtrl, specialty.notExists);
        }
      }
    });
    if (this.isDentist) {
      this.selectedSpecialties = this.specialtiesForm.value.map(({ specialty }) => specialty);
      this.addSpecialties();
    }
  }

  private getDateOfBirth(date: Date): string {
    return moment(date)
      .utcOffset(0)
      .format('YYYY-MM-DD');
  }

  addSpecialties() {
    if (this.selectedSpecialties.length <= 3) {
      const specialtiesArray = this.selectedSpecialties.map(_id => this.buildSpecialtyForm({ _id }));
      this.form.setControl(
        'specialties',
        this.fb.array(specialtiesArray, ValidationService.minLengthArray(1))
      );
    } else if (this.selectedSpecialties.length > 3) {
      this.selectedSpecialties.pop();
      this.notification.warning('Aviso', 'Você pode escolher no máximo 3 especialidades');
    }
  }

  checkRQE(rqeForm: FormGroup, disable: boolean) {
    if (disable) {
      rqeForm.setValidators(null);
      rqeForm.setValue(null);
      rqeForm.disable();
    } else {
      rqeForm.setValidators(Validators.required);
      rqeForm.markAsPristine();
      rqeForm.enable();
    }
  }

  addSpecialty() {
    this.specialtiesForm.push(this.buildSpecialtyForm());
  }

  private buildSpecialtyForm({ _id = null, rqe = null, notExists = null } = {}) {
    return this.fb.group({
      specialty: [_id, Validators.required],
      rqe: [rqe, this.isDentist || this.isVeterinarian ? null : Validators.required],
      notExists: [notExists]
    });
  }

  trackById(index, specialty: Specialty) {
    return specialty ? specialty.code : null;
  }

  async submitForm() {
    markFormGroup(this.form);
    try {
      if (this.form.value.specialties && this.form.value.specialties.length === 0) {
        this.notification.warning('Atenção', 'Você precisa escolher ao menos uma especialidade.');
        return;
      }

      if (this.form.valid) {
        this.loading = true;
        if (this.user.userType === 'patient') {
          this.completeUser = await this.patientService
            .update(this.completeUser._id, this.form.value)
            .toPromise();
        } else if (this.user.userType === 'pharmacist') {
          this.completeUser = await this.pharmacistService
            .update(this.completeUser._id, this.form.value)
            .toPromise();
        } else if (this.user.userType === 'healthProfessional') {
          this.completeUser = await this.healthProfessionalService
            .update(this.completeUser.userId, this.form.value)
            .toPromise();
        } else {
          this.completeUser = await this.pharmacyService
            .update(this.completeUser._id, this.form.value)
            .toPromise();
        }
        this.form.enable();
        this.updateUserEvent.emit(this.completeUser);
        this.loading = false;
        this.nzModalRef.destroy();
        this.notification.success('Sucesso', 'Dados salvos com sucesso');
      } else {
        ValidationService.printInvalidFields(this.form);
      }
    } catch (err) {
      this.loading = false;
      console.error(err);
      this.notification.error('Erro', 'Não foi possível salvar os dados');
    }
  }
}
