import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Patient } from '@app/shared/models';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

export const PATIENT_URL = 'patient';

@Injectable({ providedIn: 'root' })
export class PatientsService {
  constructor(private http: HttpClient) {}

  save(patient: Patient): Observable<Patient> {
    return this.http.post<Patient>(`${environment.apiRoot}${PATIENT_URL}`, patient);
  }

  getPatient(id: string): Observable<Patient> {
    return this.http.get<Patient>(`${environment.apiRoot}${PATIENT_URL}`, { params: { id } });
  }

  getPatientByCpf(cpf: string): Observable<Patient & Patient[]> {
    return this.http.get<Patient & Patient[]>(`${environment.apiRoot}${PATIENT_URL}`, {
      params: { cpf }
    });
  }

  getPatientByEmail(email: string): Observable<Patient[]> {
    return this.http.get<Patient[]>(`${environment.apiRoot}${PATIENT_URL}/?email=${email}`);
  }

  getPatients(): Observable<Patient[]> {
    return this.http.get<Patient[]>(`${environment.apiRoot}${PATIENT_URL}`);
  }

  getPatientByUserId(userId: string): Observable<Patient> {
    return this.http.get<Patient>(`${environment.apiRoot}${PATIENT_URL}/user/${userId}`);
  }

  update(id: string, data: any): Observable<Patient> {
    return this.http.put<Patient>(`${environment.apiRoot}${PATIENT_URL}/${id}`, data);
  }

  consultCpf(cpf: string): Observable<any> {
    return this.http.get<any>(`${environment.apiRoot}${PATIENT_URL}/consult/${cpf}`);
  }

  getPatientsByFilter(filter: any): Observable<Patient[]> {
    return this.http.get<any>(`${environment.apiRoot}${PATIENT_URL}/filter`, { params: filter });
  }

  resolvePending() {
    return this.http.post(`${environment.apiRoot}${PATIENT_URL}/pendency`, {});
  }

  sendDocument(data) {
    return this.http.post(`${environment.apiRoot}${PATIENT_URL}/send-document`, data);
  }

  exportData() {
    return this.http.get(`${environment.apiRoot}${PATIENT_URL}/export`).subscribe((response: any) => {
      const blob = new Blob([response.data], { type: 'text/csv' });
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = 'pacientes.csv';
      a.click();
    });
  }

  unsubscribePatient(id: string) {
    return this.http.delete(`${environment.apiRoot}${PATIENT_URL}/${id}`);
  }

  existsPatient(cpf: string) {
    return this.http.get(`${environment.apiRoot}${PATIENT_URL}/cpf/${cpf}/exists`);
  }
}
