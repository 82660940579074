import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-contacts-data-form',
  templateUrl: './contacts-data-form.component.html'
})
export class ContactsDataFormComponent {
  @Input() form: FormGroup;
  @Input() showAlertMsg = true;
}
