<fieldset class="w-100 mb-2" [formGroup]="form" theme>
  <legend>Paciente Pet</legend>

  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="nome">Nome do Pet<span class="mandatory"> *</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            nz-input
            placeholder="ex: Rex"
            formControlName="name"
            id="nome"
            maxlength="128"
            autocomplete="disabled"
          />
          <nz-form-explain *ngIf="form.get('name').dirty && form.get('name').errors">
            <ng-container *ngIf="form.get('name').hasError('required')">
              Campo obrigatório, por favor digite o nome e sobrenome.
            </ng-container>
            <ng-container
              *ngIf="!form.get('name').hasError('required') && form.get('name').hasError('nome')"
            >
              Por favor digite o nome e sobrenome.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="species">Espécie<span class="mandatory"> *</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <select
            id="species"
            nz-input
            formControlName="species"
            class="capitalize"
            (change)="setSpecies($event.target.value)"
          >
            <option value="" selected>Selecionar</option>
            <option *ngFor="let spec of species" [value]="spec.title">{{ spec.title }}</option>
          </select>
          <nz-form-explain *ngIf="form.get('species').dirty && form.get('species').errors">
            <ng-container *ngIf="form.get('species').hasError('required')">
              Campo obrigatório, por favor selecione a espécie.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="race">Raça</nz-form-label>
        <nz-form-control nzHasFeedback>
          <select
            nz-input
            id="race"
            formControlName="race"
            class="capitalize"
            *ngIf="races?.length > 0; else raceInput"
          >
            <option value="" selected>Selecionar</option>
            <option *ngFor="let race of races" [value]="race.title">{{ race.title }}</option>
          </select>
          <ng-template #raceInput>
            <input nz-input id="race" formControlName="race" autocomplete="disabled" />
          </ng-template>
          <nz-form-explain *ngIf="form.get('race').dirty && form.get('race').errors">
            <ng-container *ngIf="form.get('race').hasError('required')">
              Campo obrigatório, por favor selecione a raça.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3 col-md-3 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="dateOfBirth">Data de nascimento</nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            nz-input
            type="date"
            formControlName="dateOfBirth"
            placeholder="DD/MM/AAAA"
            id="dateOfBirth"
            [max]="currentDate"
          />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="approximateAge">Idade aproximada (meses)</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            id="approximateAge"
            type="number"
            (change)="changeApproximateAge($event.target.value)"
          />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="sex">Sexo</nz-form-label>
        <nz-form-control nzHasFeedback>
          <select formControlName="sex" id="sex" nz-input nzPlaceHolder="Selecione">
            <option value="M">Masculino</option>
            <option value="F">Feminino</option>
          </select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="fur">Pelagem</nz-form-label>
        <nz-form-control nzHasFeedback>
          <select formControlName="fur" id="fur" nz-input nzPlaceHolder="Selecione">
            <option *ngFor="let fur of furs" [value]="fur.title" class="capitalize">{{
              fur.title | capitalize
            }}</option>
          </select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3 col-md-3 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="weight">Peso (kg)</nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            nz-input
            type="number"
            formControlName="weight"
            placeholder="0.0"
            id="weight"
            autocomplete="disabled"
          />

          <nz-form-explain *ngIf="form.get('weight').dirty && form.get('weight').errors">
            <ng-container *ngIf="form.get('weight').hasError('required')">
              Campo obrigatório, por favor digite data de nascimento.
            </ng-container>
            <ng-container
              *ngIf="!form.get('weight').hasError('required') && form.get('weight').hasError('weight')"
            >
              Nome inválido.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="castration">Castração</nz-form-label>
        <nz-form-control nzHasFeedback>
          <select formControlName="castration" id="castration" nz-input nzPlaceHolder="Selecione">
            <option [ngValue]="true">Sim</option>
            <option [ngValue]="false">Não</option>
          </select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="temperament">Temperamento</nz-form-label>
        <nz-form-control nzHasFeedback>
          <select formControlName="temperament" id="temperament" nz-input nzPlaceHolder="Selecione">
            <option value="aggressive">Agressivo</option>
            <option value="fearful">Medroso</option>
            <option value="dominant">Dominante</option>
            <option value="hyperactive">Hiperativo</option>
            <option value="alert">Alerta</option>
            <option value="docile">Dócil</option>
          </select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="microchipNumber">Nº do Microchip</nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            nz-input
            formControlName="microchipNumber"
            id="microchipNumber"
            autocomplete="disabled"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</fieldset>
