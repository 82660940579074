import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HealthProfessionalService } from '@app/modules/health-professional/services/health-professional.service';
import { DialogModalComponent } from '@app/shared/components/dialog-modal/dialog-modal.component';
import { DateUtils } from '@app/shared/data/DateUtils';
import { Paciente } from '@app/shared/models/paciente';
import { logInvalidForm } from '@app/utils/logInvalidForm';
import { markFormGroup } from '@app/utils/markFormGroup';
import { NzNotificationService } from 'ng-zorro-antd';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PatientsService } from '../../services/patients.service';
import { PetPatientService } from '../../services/pet-patient.service';
import { ProfessionalType } from '@app/shared/models/professional-type.enum';

@Component({
  selector: 'app-modal-add-patient',
  templateUrl: './modal-add-patient.component.html',
  styleUrls: ['./modal-add-patient.component.scss']
})
export class ModalAddPatientComponent {
  @Input() prescriber: any;
  @Input() patient: Paciente;
  @Input() isPet = false;
  @Input() isResponsible = false;
  @Input() existingPatient = false;
  @Input() registeredByResponsible = false;
  @Output() updateListPatientEvent = new EventEmitter<any>();

  @ViewChild(DialogModalComponent, { static: true })
  dialogModal: DialogModalComponent;

  loading = false;
  form: FormGroup;

  constructor(
    private router: Router,
    public modalRef: BsModalRef,
    private patientService: PatientsService,
    private petPatientService: PetPatientService,
    private healthProfessionalService: HealthProfessionalService,
    private notification: NzNotificationService
  ) {}

  get hasPendency() {
    return !this.patient || this.patient.status !== 1;
  }

  get isUnderAge() {
    // the pet's age doesn't matter
    if (this.isVeterinarian) {
      return true;
    }

    if (!this.hasPendency) {
      const nascimento = this.patient.nascimento;
      return DateUtils.isUnderAge(nascimento, 'DD/MM/YYYY');
    }
    return this.patient.menorIdade;
  }

  get isVeterinarian(): boolean {
    return (
      this.isPet ||
      (this.prescriber && this.prescriber.professionalType === ProfessionalType.VETERINARIAN)
    );
  }

  async submit() {
    markFormGroup(this.form);

    const form = this.form.getRawValue();
    if (!form.pendency && this.patient.status === -1) {
      form.pendency = this.patient;
    }

    if (this.form.valid) {
      try {
        this.loading = true;
        const { responsible } = form;
        if (responsible) {
          const responsibleSaved = responsible._id
            ? await this.updatePatient(responsible._id, responsible)
            : await this.savePatient(responsible);
          form.responsible = responsibleSaved._id;
        }

        if (!this.registeredByResponsible) {
          const patientSaved = await this.saveAddPatient(form);

          const msg =
            !form.email && !this.isPet
              ? 'E-mail do paciente não cadastrado.<br>' +
                'Seu paciente precisará fazer um novo<br>cadastro para acessar o Perfil do Paciente'
              : '';

          this.notification.success('Paciente cadastrado com sucesso!', msg);
          this.updateListPatientEvent.emit(patientSaved);
        } else {
          await this.savePatient(form);

          this.dialogModal.createTplModal(
            'Parabéns! \n Cadastro realizado com sucesso.',
            'Acesse seu e-mail e ative sua conta!'
          );
          this.router.navigate(['/entry/login']);
        }
        this.modalRef.hide();
      } catch (err) {
        console.error(err);
        const msg = err.error ? err.error.message : 'Erro ao salvar paciente';
        this.notification.error('Erro', msg);
        if (msg === 'E-mail já cadastrado') {
          if (this.isVeterinarian) {
            this.form.get('responsible.email').setErrors({ alreadyExists: true });
          } else {
            this.form.get('email').setErrors({ alreadyExists: true });
          }
        } else {
          if (this.isVeterinarian) {
            this.form.get('responsible.email').setErrors(null);
          } else {
            this.form.get('email').setErrors(null);
          }
        }
      } finally {
        this.loading = false;
      }
    } else {
      logInvalidForm(this.form);
    }
  }

  private savePatient(patient: any) {
    patient.originHost = window.location.origin;
    if (this.isVeterinarian && this.isPet) {
      return this.petPatientService.save(patient).toPromise();
    }
    return this.patientService.save(patient).toPromise();
  }

  private updatePatient(responsibleId, patient: any) {
    patient.originHost = window.location.origin;
    return this.patientService.update(responsibleId, patient).toPromise();
  }

  private saveAddPatient(patient: any) {
    patient.originHost = window.location.origin;
    if (this.isVeterinarian) {
      return this.healthProfessionalService.savePetPatient(patient).toPromise();
    }

    return this.healthProfessionalService.savePatient(this.prescriber._id, patient).toPromise();
  }

  private addPatient(patientId: string) {
    return this.healthProfessionalService.addPatient(this.prescriber._id, patientId).toPromise();
  }

  close() {
    this.modalRef.hide();
  }
}
