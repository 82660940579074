import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from '@app/core/services/validation.service';
import { CepService } from '@app/modules/entry/services/cep.service';
import { BrazilianStates } from '@app/shared/data/Brazilian-states';
import { BrazilState, Fur, Patient } from '@app/shared/models';
import { markFormGroup } from '@app/utils/markFormGroup';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { catchError, debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { UserService } from '@app/modules/user/user.service';
import { of } from 'rxjs';
import { PetPatient } from '@app/shared/models/pet-patient';
import { Furs } from '@app/shared/data/Fur';
import { Race } from '@app/shared/models/races';
import { Species } from '@app/shared/models/species';
import { PetPatientService } from '../../services/pet-patient.service';
import moment from 'moment';

@Component({
  selector: 'app-modal-edit-pet',
  templateUrl: './modal-edit-pet.component.html',
  styleUrls: ['./modal-edit-pet.component.scss']
})
export class ModalEditPetComponent implements OnInit {
  @Input() pet: PetPatient;
  @Input() isVeterinarian: boolean;
  @Output() submit = new EventEmitter<Patient>();

  states: BrazilState[] = BrazilianStates;
  loading = false;
  species: Species[];
  races: Race[];
  furs: Fur[] = Furs;

  form = this.fb.group({
    _id: [null],
    name: ['', Validators.required],
    species: ['', Validators.required],
    sex: [''],
    race: [''],
    dateOfBirth: [''],
    weight: [null],
    fur: [''],
    castration: [false, Validators.required],
    temperament: [''],
    microchipNumber: [''],
    responsible: this.fb.group({
      _id: [null],
      cpf: ['', [Validators.required, ValidationService.cpfValidator]],
      fullname: ['', Validators.required],
      name: ['', Validators.required],
      sex: ['', Validators.required],
      dateOfBirth: ['', Validators.required],
      membership: this.fb.group({
        mothersName: ['']
      }),
      cellphone: [''],
      telephone: [''],
      email: ['', Validators.email],
      emailContact: ['', Validators.email],
      address: this.fb.group({
        uf: [''],
        city: [''],
        complement: [''],
        number: [''],
        street: [''],
        neighborhood: [''],
        cep: ['']
      }),
      pendency: [null]
    })
  });

  constructor(
    private modalRef: BsModalRef,
    private fb: FormBuilder,
    private cepService: CepService,
    private petPatientService: PetPatientService
  ) {}

  async ngOnInit() {
    this.species = await this.petPatientService.getSpecies().toPromise();
    this.patchForm();
  }

  private patchForm() {
    this.form.patchValue(this.pet);

    const date = new Date(this.pet.dateOfBirth);
    this.form.get('dateOfBirth').setValue(date.toISOString().substring(0, 10));

    this.form.get('responsible').setValue(this.pet.responsible._id);
  }

  get cpfCtrl() {
    return this.form.get('cpf');
  }

  get dateCtrl() {
    return this.form.get('dateOfBirth');
  }

  get currentDate() {
    const now = new Date();
    const dd = String(now.getDate()).padStart(2, '0');
    const mm = String(now.getMonth() + 1).padStart(2, '0');
    const yyyy = now.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  }

  get isDependent() {
    return this.pet.responsible;
  }

  checkRepeatName(value: boolean) {
    if (value) {
      this.form.get('name').setValue(this.form.get('fullname').value);
      this.form.get('name').disable();
    } else {
      this.form.get('name').enable();
    }
  }

  async onKey(event: any) {
    if (event.target.value.length === 10) {
      const cep = event.target.value.replace(/[^\d]+/g, '');
      const data = await this.cepService.consult(cep);
      if (!data.erro) {
        this.form.controls['address'].setValue({
          uf: data.uf,
          street: data.logradouro,
          neighborhood: data.bairro,
          city: data.localidade,
          complement: data.complemento,
          number: null,
          cep: data.cep
        });
      }
    }
  }

  submitForm() {
    markFormGroup(this.form);
    const form = this.form.getRawValue();

    if (this.form.valid) {
      this.submit.emit(form);
      this.close();
    }
  }

  close() {
    this.modalRef.hide();
  }

  async setSpecies(title: any) {
    const species = this.species.find(s => s.title === title);
    await this.getRaces(species._id);
  }

  async getRaces(speciesId: string) {
    this.form.get('race').setValue('');
    this.races = await this.petPatientService.getRaces(speciesId).toPromise();
  }

  changeApproximateAge(months: string) {
    if (months) {
      const years = +months > 0 ? +months : 0;
      const dateOfBirth = moment()
        .subtract(years, 'month')
        .format('YYYY-MM-DD');
      this.form.get('dateOfBirth').setValue(dateOfBirth);
    }
  }
}
