import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PrescriptionService } from '../../../modules/document/services/prescription.service';
import { User } from './../../models/decodedLoginToken';

@Component({
  selector: 'app-modal-search',
  templateUrl: './modal-search.component.html',
  styleUrls: ['./modal-search.component.scss']
})
export class ModalSearchComponent {
  constructor(
    private router: Router,
    public modalRef: BsModalRef,
    private notification: NzNotificationService,
    private prescriptionService: PrescriptionService,
    private authService: AuthService
  ) {}

  get user(): User {
    return this.authService.user();
  }

  get hasFarmaRennovaFeature(): boolean {
    return this.user && this.user.features.some(f => f.name === 'Farma Rennova');
  }

  search(param: string) {
    param = param === undefined ? '' : param.replace(/[^a-z0-9]+/gi, '');
    if (param === '') {
      this.modalRef.hide();
      this.notification.warning('Atenção', 'CPF ou Código da Receita não informado');
    } else if (param.length === 11 || param.length === 15) {
      if (param.length === 15 && !this.hasFarmaRennovaFeature && param.substring(0, 3) === 'REN') {
        this.notification.warning('Atenção', 'Código informado inválido');
      } else {
        this.requests(param);
      }
    } else {
      this.modalRef.hide();
      this.notification.warning('Atenção', 'CPF ou código inválido');
    }
  }

  private async requests(param: string) {
    try {
      const prescriptions = await this.prescriptionService.getPrescriptionsPatient(param).toPromise();

      if (param.length === 11) {
        if (prescriptions.length === 0) {
          this.notification.warning(
            'Atenção',
            'Não existe prescrições com medicamentos a serem dispensados para o CPF informado'
          );
          return;
        }

        this.router.navigate(['/document/listing-prescriptions/', param], {
          state: { prescriptions: prescriptions }
        });
      } else {
        this.router.navigate(['/document/confirmation', param], {
          state: { prescription: prescriptions }
        });
      }
      this.modalRef.hide();
    } catch (error) {
      this.modalRef.hide();
      if (error.status === 404) {
        this.notification.warning('Atenção', error.error.message);
      }
    }
  }
}
