<!--Dados de Identificação ou Responsável-->
<fieldset class="w-100 mb-2" [formGroup]="form" theme>
  <legend *ngIf="!isResponsible">
    Dados de Identificação <span class="mandatory">*</span> (dados mínimos obrigatórios)
  </legend>
  <legend *ngIf="isResponsible">
    Dados do Responsável <span class="mandatory">*</span> (dados mínimos obrigatórios)
  </legend>
  <ng-container *ngIf="showAlertMsg">
    <nz-alert
      *ngIf="!isResponsible"
      class="mb-4"
      nzType="warning"
      nzMessage="Ao preencher estes dados, você será capaz de emitir Receitas Simples, Receitas de Antimicrobianos, Atestados, Pedidos de Exames, Laudos, Relatórios, Orientações e Recibos."
    ></nz-alert>
    <nz-alert
      *ngIf="isResponsible"
      class="mb-4"
      nzType="warning"
      nzMessage="Obrigatório cadastrar um responsável para pacientes menores de idade."
    ></nz-alert>
  </ng-container>

  <div class="row">
    <div class="col-lg-4 col-md-4 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="cpf">CPF<span class="mandatory"> *</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            nz-input
            id="cpf"
            formControlName="cpf"
            placeholder="000.000.000-00"
            mask="000.000.000-00"
            autocomplete="disabled"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12">
      <nz-form-item *ngIf="form.get('dateOfBirth') as ctrl">
        <nz-form-label nzFor="dateOfBirth"
          >Data de nascimento<span class="mandatory"> *</span>
        </nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            nz-input
            id="dateOfBirth"
            type="date"
            formControlName="dateOfBirth"
            placeholder="DD/MM/AAAA"
            autocomplete="disabled"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="sex">Sexo<span class="mandatory"> *</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <select formControlName="sex" id="sex" nz-input nzPlaceHolder="Selecione">
            <option value="M">Masculino</option>
            <option value="F">Feminino</option>
          </select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-4 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="fullname">Nome completo<span class="mandatory"> *</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            formControlName="fullname"
            id="fullname"
            nz-input
            placeholder="ex: João Gilberto"
            maxlength="128"
            autocomplete="disabled"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-lg-6 col-md-4 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="name"
          >Nome de exibição/social<span class="mandatory"> *</span></nz-form-label
        >
        <nz-form-control nzHasFeedback>
          <input
            formControlName="name"
            id="name"
            nz-input
            placeholder="ex: João Gilberto"
            maxlength="128"
            autocomplete="disabled"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-4 col-sm-12" [formGroup]="form.get('membership')">
      <nz-form-item>
        <nz-form-label nzFor="mothersName">Nome da mãe</nz-form-label>

        <nz-form-control nzHasFeedback>
          <input
            formControlName="mothersName"
            id="mothersName"
            nz-input
            maxlength="128"
            autocomplete="disabled"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</fieldset>
