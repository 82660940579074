<div class="modal-container">
  <div theme class="modal-header">
    <h4 class="modal-title pull-left text-white">Produtos Rennova</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="align-flex-center" *ngIf="loading">
    <nz-spin nzSimple></nz-spin>
  </div>
  <div class="modal-body" *ngIf="!loading && products?.length > 0">
    <nz-list nzBordered>
      <nz-list-item *ngFor="let product of products">
        <div class="d-flex flex-column w-100">
          <div class="item-container align-items-center">
            <div class="d-flex align-items-center">
              <div (click)="activeChange(product)" style="padding: 10px;">
                <i nz-icon nzType="right" *ngIf="!product.active" nzTheme="outline"></i>
                <i nz-icon nzType="down" *ngIf="product.active" nzTheme="outline"></i>
              </div>
              <span style="margin-top: 3px; font-weight: 600;"> {{ product.name }}</span>
            </div>
            <button class="ok-btn btn-selecionar" nz-button (click)="onSelect(product)">
              Adicionar
            </button>
          </div>
          <div class="p-3" *ngIf="product?.active">
            <div *ngFor="let item of product.items">
              <h6>{{ item.name }}</h6>
              <ul *ngIf="item.descriptions.length > 0">
                <li *ngFor="let obj of item.descriptions">
                  <div class="d-flex">
                    <span style="text-align: justify; font-family: monospace;">
                      {{ obj?.fullDescription }}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <p style="font-weight: 700; text-align: justify;">{{ product.observation }}</p>
          </div>
        </div>
      </nz-list-item>
    </nz-list>
  </div>
  <div class="align-flex-center" *ngIf="!loading && products?.length === 0">
    <h5>Nenhum produto cadastrado</h5>
  </div>
</div>
