import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '@app/core/services/validation.service';
import { UserService } from '@app/modules/user/user.service';
import { Patient } from '@app/shared/models';
import { Paciente } from '@app/shared/models/paciente';
import { environment } from '@env/environment';
import moment from 'moment';
import { of } from 'rxjs';
import { map, filter, debounceTime, switchMap, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-simplified-pet-patient-form',
  templateUrl: './simplified-pet-patient-form.component.html',
  styleUrls: ['./simplified-pet-patient-form.component.scss']
})
export class SimplifiedPetPatientFormComponent implements OnInit {
  @Input() responsible: Paciente & Patient;
  @Input() responsibleExists = false;
  @Output() formChange = new EventEmitter<FormGroup>();

  form: FormGroup;

  constructor(private fb: FormBuilder, private userService: UserService) {
    this.buildForm();
  }

  private buildForm() {
    this.form = this.fb.group({
      _id: [null],
      name: ['', Validators.required],
      species: ['', Validators.required],
      sex: [''],
      race: [''],
      dateOfBirth: [''],
      weight: [null],
      fur: [''],
      castration: [false, Validators.required],
      temperament: [''],
      microchipNumber: [''],
      responsible: this.fb.group({
        _id: [null],
        cpf: ['', [Validators.required, ValidationService.cpfValidator]],
        fullname: ['', Validators.required],
        name: ['', Validators.required],
        sex: ['', Validators.required],
        dateOfBirth: ['', Validators.required],
        membership: this.fb.group({
          mothersName: ['']
        }),
        cellphone: [''],
        telephone: [''],
        email: ['', Validators.email],
        emailContact: ['', Validators.email],
        address: this.fb.group({
          uf: [''],
          city: [''],
          complement: [''],
          number: [''],
          street: [''],
          neighborhood: [''],
          cep: ['']
        }),
        pendency: [null]
      })
    });
  }

  ngOnInit(): void {
    if (this.responsibleExists) {
      this.patchResponsibleExists(this.responsible);
      this.disableFields();
    } else {
      this.patchResponsibleNotExists(this.responsible);
      this.formResponsible.get('cpf').disable();
    }

    this.formChange.emit(this.form);
    const emailForm = this.form.get('responsible.email');
    emailForm.valueChanges
      .pipe(
        map(email => {
          const value = email && email.toLowerCase();
          emailForm.setValue(value, { emitEvent: false });
          return value;
        }),
        filter(
          _ =>
            emailForm.valid &&
            emailForm.value &&
            (!this.responsibleExists || emailForm.value !== this.responsible.user.email)
        ),
        debounceTime(environment.debounceTime),
        switchMap(email => this.userService.checkEmailExists(email).pipe(catchError(err => of(err))))
      )
      .subscribe(res => {
        if (res.status === 200) {
          emailForm.setErrors({ exists: true });
        } else if (res.status === 404) {
          emailForm.setErrors(null);
        } else if (res.status === 400) {
          emailForm.setErrors({ invalid: true });
        }
      });

    this.form.get('responsible.emailContact').valueChanges.subscribe(email => {
      this.form
        .get('responsible.emailContact')
        .setValue(email && email.toLowerCase(), { emitEvent: false });
    });
  }

  get formResponsible(): FormGroup {
    return <FormGroup>this.form.get('responsible');
  }

  private patchResponsibleExists(responsible: Patient) {
    this.formResponsible.patchValue(responsible);
    if (responsible.dateOfBirth) {
      const dateStr = responsible.dateOfBirth.toString().split('T')[0];
      const dateOfBirth = moment(dateStr).format('YYYY-MM-DD');
      this.formResponsible.get('dateOfBirth').setValue(dateOfBirth);
    }
    if (responsible.user && responsible.user.email) {
      this.formResponsible.get('email').setValue(responsible.user.email);
    }
  }

  private patchResponsibleNotExists(responsible: Paciente) {
    const nascimento = moment(responsible.nascimento, 'DD/MM/YYYY');
    this.formResponsible.patchValue({
      cpf: responsible.cpf,
      fullname: responsible.nome,
      name: responsible.nome,
      sex: responsible.genero,
      dateOfBirth: nascimento.format('YYYY-MM-DD'),
      membership: {
        mothersName: responsible.mae
      }
    });
  }

  private disableFields() {
    this.formResponsible.get('cpf').disable();
    this.formResponsible.get('fullname').disable();
    this.formResponsible.get('dateOfBirth').disable();
  }
}
