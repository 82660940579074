import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { ValidationService } from '@app/core/services/validation.service';
import { DocumentService } from '@app/modules/document/services/document.service';
import { PrescriptionService } from '@app/modules/document/services/prescription.service';
import { PatientsService } from '@app/modules/patients/services/patients.service';
import { PetPatientService } from '@app/modules/patients/services/pet-patient.service';
import { UserService } from '@app/modules/user/user.service';
import { Patient } from '@app/shared/models';
import { ColorSetting } from '@app/shared/models/color-setting';
import { User } from '@app/shared/models/decodedLoginToken';
import { RennovaService } from '@app/shared/services/rennova.service';
import { StateService } from '@app/shared/services/state.service';
import { markFormGroup } from '@app/utils/markFormGroup';
import { NzModalRef, NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalSucessSendDocumentComponent } from '../modal-sucess-send-document/modal-sucess-send-document.component';

@Component({
  selector: 'app-modal-send-document',
  templateUrl: './modal-send-document.component.html',
  styleUrls: ['./modal-send-document.component.scss']
})
export class ModalSendDocumentComponent implements OnInit {
  @Input() colorSetting: ColorSetting;
  @Output() closeModal = new EventEmitter<any>();

  tplModal: NzModalRef;

  @ViewChild('tplTitle', { static: false })
  tplTitle: TemplateRef<{}>;

  @ViewChild('tplContent', { static: true })
  tplContent: TemplateRef<{}>;

  @ViewChild('tplFooter', { static: true })
  tplFooter: TemplateRef<{}>;

  title: string;
  code: string;
  loading = false;
  resend = false;
  sendDocumentRequired = false;
  showDownloadSignedPdf = true;

  private patient: Patient;

  sendForm = this.fb.group(
    {
      name: [''],
      emailChecked: [false],
      smsChecked: [false],
      whatsappChecked: [false],
      patientId: [''],
      sms: ['', Validators.compose([ValidationService.celularValidator])],
      email: ['', Validators.email],
      whatsapp: ['']
    },
    { validator: Validators.compose([this.requiredEmail, this.requiredSms, this.requiredWhatsapp]) }
  );

  constructor(
    private fb: FormBuilder,
    private modalService: NzModalService,
    private bsModalService: BsModalService,
    private patientService: PatientsService,
    private petPatientService: PetPatientService,
    private authService: AuthService,
    private prescriptionService: PrescriptionService,
    private notification: NzNotificationService,
    private userService: UserService,
    private documentService: DocumentService,
    private stateService: StateService,
    private router: Router,
    private rennovaService: RennovaService
  ) {}

  ngOnInit() {
    if (!this.isRennova) {
      this.listenFields();
    }
  }

  get userLogin(): User {
    return this.authService.user();
  }

  get isRennova() {
    return this.stateService.data && this.stateService.data.isRennova;
  }

  requiredEmail(control: FormControl): { [key: string]: boolean } | null {
    if (!control.get('emailChecked').value) {
      control.get('email').setErrors(null);
    } else if (!control.get('email').value) {
      control.get('email').setErrors({ requiredEmail: true });
    }
    return null;
  }

  requiredSms(control: FormControl): { [key: string]: boolean } | null {
    if (!control.get('smsChecked').value) {
      control.get('sms').setErrors(null);
    } else if (!control.get('sms').value) {
      control.get('sms').setErrors({ requiredSms: true });
    }
    return null;
  }

  requiredWhatsapp(control: FormControl): { [key: string]: boolean } | null {
    if (!control.get('whatsappChecked').value) {
      control.get('whatsapp').setErrors(null);
    } else if (!control.get('whatsapp').value) {
      control.get('whatsapp').setErrors({ requiredWhatsapp: true });
    }
    return null;
  }

  get emailChecked() {
    return this.sendForm.get('emailChecked').value;
  }

  get smsChecked() {
    return this.sendForm.get('smsChecked').value;
  }

  get whatsappChecked() {
    return this.sendForm.get('whatsappChecked').value;
  }

  get email() {
    return this.sendForm.get('email');
  }

  get sms() {
    return this.sendForm.get('sms');
  }

  get whatsapp() {
    return this.sendForm.get('whatsapp');
  }

  get linkAcesso() {
    return `${window.location.origin}/p/${this.code}`;
  }

  copyLinkAccess() {
    navigator.clipboard.writeText(this.linkAcesso);
    this.notification.success('Sucesso', 'Link de acesso copiado!');
  }

  async createTplModal(
    code: string,
    patientId: string,
    {
      title = '',
      resend = false,
      sendDocumentRequired = false,
      showDownloadSignedPdf = true,
      isPet = false
    } = {}
  ) {
    this.code = code;
    this.resend = resend;
    this.sendDocumentRequired = sendDocumentRequired;
    this.showDownloadSignedPdf = showDownloadSignedPdf;

    switch (code[0]) {
      case 'P':
      case 'I':
      case 'V':
        this.title = 'Receita salva com sucesso!';
        break;
      case 'A':
        this.title = 'Atestado salvo com sucesso!';
        break;
      case 'E':
        this.title = 'Pedido de exame salvo com sucesso!';
        break;
      case 'O':
        this.title = 'Documento de orientações salvo com sucesso!';
        break;
      default: {
        if (code.substring(0, 3) === 'REN') {
          this.title = 'Documento enviado com sucesso!';
        } else {
          this.title = 'Documento salvo com sucesso!';
        }
      }
    }

    if (title) {
      this.title = title;
    }

    this.create();

    if (isPet) {
      await this.getPet(patientId);
    } else {
      await this.getPatient(patientId);
    }
  }

  private create() {
    this.tplModal = this.modalService.create({
      nzContent: this.tplContent,
      nzFooter: this.tplFooter,
      nzClosable: false,
      nzWidth: 650,
      nzMaskClosable: false,
      nzClassName: 'modal-send-document',
      nzOnOk: () => {},
      nzOnCancel: () => {}
    });

    this.tplModal.afterOpen.subscribe(() => {
      const elements = document.getElementsByClassName('ant-modal-footer');
      if (elements && this.colorSetting) {
        elements[0].setAttribute('style', `background-color: ${this.colorSetting.footer}`);
      }
    });
  }

  async getPatient(patientId: string) {
    try {
      this.loading = true;
      this.patient = await this.patientService.getPatient(patientId).toPromise();
      this.initForm(this.patient);
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
  }

  async getPet(petId: string) {
    try {
      this.loading = true;
      const { responsible } = await this.petPatientService.getPetById(petId).toPromise();
      this.patient = responsible;
      if (this.patient) {
        this.initForm(this.patient);
      }
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
  }

  private initForm(patient: Patient) {
    let email = patient.user && (patient.user.emailContact || patient.user.email);
    if (!email && patient.responsible && patient.responsible.user) {
      email = patient.responsible.user.emailContact || patient.responsible.user.email;
    }

    if (email) {
      this.sendForm.get('email').setValue(email);
      this.sendForm.get('emailChecked').setValue(!!email);
      this.sendForm.get('email').markAsDirty();
    }

    let cellphone = patient.cellphone || patient.telephone;
    if (patient.responsible) {
      cellphone = patient.responsible.cellphone || patient.responsible.telephone;
    }
    this.sendForm.get('sms').setValue(cellphone);
    this.sendForm.get('smsChecked').setValue(!!cellphone);
    this.sendForm.get('sms').markAsDirty();
    this.sendForm.get('whatsapp').setValue(cellphone);
    this.sendForm.get('whatsappChecked').setValue(!!cellphone);
    this.sendForm.get('whatsapp').markAsDirty();
    this.sendForm.get('patientId').setValue(patient._id);
  }

  async sendRennova() {
    this.sendEmailsRennova(this.code);
    this.showModalSuccessSendDocument();
  }

  async send() {
    markFormGroup(this.sendForm);
    try {
      if (this.sendForm.valid) {
        if (this.someFieldWasChecked) {
          this.loading = true;
          this.updatePatient();
          const data = {
            email: this.emailChecked ? this.sendForm.value.email : undefined,
            sms: this.smsChecked ? this.sendForm.value.sms : undefined,
            name: this.sendForm.value.name,
            code: this.code,
            patientId: this.sendForm.value.patientId,
            originHost: window.location.origin
          };
          await this.patientService.sendDocument(data).toPromise();

          if (this.whatsappChecked) {
            await this.openWhatsAppTab();
          }
        }

        if (this.resend) {
          this.showModalSuccessSendDocument();
        } else if (this.userLogin) {
          this.loading = true;
          const { doNotShowAgainModalSuccessPrescription } = await this.userService
            .getControllerUserUi(this.userLogin._id)
            .toPromise();

          if (!doNotShowAgainModalSuccessPrescription) {
            this.showModalSuccessSendDocument();
          }
        }
        this.loading = false;
      }

      this.close();
    } catch (error) {
      this.loading = false;
    }
  }

  async updatePatient() {
    const data = {
      ...this.patient,
      cellphone: this.sms.value,
      telephone: this.whatsapp.value,
      emailContact: this.email.value
    } as Patient;

    try {
      await this.patientService.update(this.patient._id, data).toPromise();
    } catch (error) {
      this.loading = false;
      this.notification.error('Error', 'Ocorreu um erro ao atualizar os dados do paciente!');
    }
  }

  get someFieldWasChecked(): boolean {
    return this.smsChecked || this.whatsappChecked || this.emailChecked;
  }

  async downloadPrescriptionPdf(code: string) {
    try {
      this.loading = true;
      await this.prescriptionService.downloadOrGeneratePdf(code);
      this.notification.success('Sucesso', 'Download realizado com sucesso!');
    } catch (err) {
      console.error(err);
    }
    this.loading = false;
  }

  private async openWhatsAppTab() {
    const documentText = this.getDocumentText(this.code);
    const doctorName = await this.getDoctorName();
    const documentLink = `${window.location.origin}/p/${this.code}`;

    const text = `Olá, ${this.patient.name}! ${documentText} ${doctorName}.
Clique no link e visualize o documento digitando os 4 primeiros dígitos do CPF do paciente: ${documentLink}
Em caso de dúvidas, acesse o atendimento em receitadigital.com`;

    let whatsapp = this.sendForm.value.whatsapp;
    const ddd = whatsapp.substring(0, 2);
    if (ddd >= 30) {
      whatsapp = whatsapp.slice(0, 2) + whatsapp.slice(3);
    }

    const phone = `55${whatsapp}`;
    const encodedText = encodeURIComponent(text);
    const url = `https://wa.me/${phone}?text=${encodedText}`;

    window.open(url, '_blank');
  }

  private getDocumentText(code: string) {
    switch (code[0]) {
      case 'P':
      case 'I':
      case 'V':
        return 'Aqui está a sua *Receita Digital* enviada';
      case 'A':
        return 'Aqui está o seu *Atestado* enviado';
      case 'E':
        return 'Aqui está o seu *Pedido de Exame* enviado';
      case 'O':
      default:
        return 'Aqui está o seu *Documento* enviado';
    }
  }

  private async getDoctorName() {
    if (this.userLogin) {
      if (this.userLogin.sex === 'F') {
        return `pela Dra. ${this.userLogin.name}`;
      }
      return `pelo Dr. ${this.userLogin.name}`;
    }

    const { healthProfessional } = await this.documentService.getDocument(this.code);
    if (healthProfessional.sex === 'F') {
      return `pela Dra. ${healthProfessional.name}`;
    }
    return `pelo Dr. ${healthProfessional.name}`;
  }

  private showModalSuccessSendDocument() {
    this.tplModal.close();
    const viewSucessMsg =
      (this.emailChecked && this.email.value) ||
      (this.smsChecked && this.sms.value) ||
      (this.whatsappChecked && this.whatsapp.value);

    const initialState = {
      code: this.code,
      resend: this.resend,
      colorSetting: this.colorSetting,
      isSuccessMsg: viewSucessMsg
    };
    const modal = this.bsModalService.show(ModalSucessSendDocumentComponent, {
      initialState,
      backdrop: 'static',
      keyboard: false
    });

    modal.content.closeModal.subscribe(() => {
      this.close(true);
    });
  }

  confirmClose() {
    if (this.resend) {
      this.close();
    } else {
      this.modalService.confirm({
        nzTitle: 'Atenção',
        nzContent: 'Tem certeza que deseja cancelar o envio do documento?',
        nzOkText: 'Sim',
        nzCancelText: 'Não',
        nzOnOk: async () => this.close()
      });
    }
  }

  close(sent?: boolean) {
    this.tplModal.destroy();
    this.closeModal.emit(sent);
    this.router.navigate(['/document/new']);
  }

  private listenFields() {
    this.sendForm.get('emailChecked').valueChanges.subscribe(checked => {
      if (!checked) {
        this.sendForm.get('email').setValue('');
      }
    });

    this.sendForm.get('email').valueChanges.subscribe(value => {
      if (value) {
        this.sendForm.get('emailChecked').setValue(true);
      }
    });

    this.sendForm.get('smsChecked').valueChanges.subscribe(checked => {
      if (!checked) {
        this.sendForm.get('sms').setValue('');
      }
    });

    this.sendForm.get('sms').valueChanges.subscribe(value => {
      if (value) {
        this.sendForm.get('smsChecked').setValue(true);
      }
    });

    this.sendForm.get('whatsappChecked').valueChanges.subscribe(checked => {
      if (!checked) {
        this.sendForm.get('whatsapp').setValue('');
      }
    });

    this.sendForm.get('whatsapp').valueChanges.subscribe(value => {
      if (value) {
        this.sendForm.get('whatsappChecked').setValue(true);
      }
    });
  }

  private sendEmailsRennova(code: string) {
    this.rennovaService.sendDocument({ code }).subscribe();
  }
}
