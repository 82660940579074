import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from '@app/core/services/validation.service';
import { CepService } from '@app/modules/entry/services/cep.service';
import { BrazilianStates } from '@app/shared/data/Brazilian-states';
import { BrazilState, Patient } from '@app/shared/models';
import { markFormGroup } from '@app/utils/markFormGroup';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { catchError, debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { UserService } from '@app/modules/user/user.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-modal-edit-patient',
  templateUrl: './modal-edit-patient.component.html',
  styleUrls: ['./modal-edit-patient.component.scss']
})
export class ModalEditPatientComponent implements OnInit {
  @Input() patient: Patient;
  @Input() isVeterinarian: boolean;
  @Output() submit = new EventEmitter<Patient>();

  states: BrazilState[] = BrazilianStates;
  loading = false;

  form = this.fb.group({
    _id: [null, Validators.required],
    name: ['', [Validators.required, ValidationService.nomeValidator]],
    fullname: ['', [Validators.required, ValidationService.nomeValidator]],
    cpf: [undefined],
    sex: ['', Validators.required],
    dateOfBirth: [null, Validators.required],
    telephone: [''],
    cellphone: [''],
    email: ['', Validators.email],
    emailContact: ['', Validators.email],
    membership: this.fb.group({
      mothersName: ['']
    }),
    responsible: [null],
    address: this.fb.group({
      uf: [''],
      city: [''],
      complement: [''],
      number: [''],
      street: [''],
      neighborhood: [''],
      cep: ['', [ValidationService.cepValidator]]
    })
  });

  constructor(
    private modalRef: BsModalRef,
    private fb: FormBuilder,
    private cepService: CepService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.patchForm();
    this.disableInputs();

    const emailForm = this.form.get('email');
    emailForm.valueChanges
      .pipe(
        map(email => {
          const value = email && email.toLowerCase();
          emailForm.setValue(value, { emitEvent: false });
          return value;
        }),
        filter(_ => emailForm.valid && emailForm.value),
        debounceTime(environment.debounceTime),
        switchMap(email =>
          this.userService
            .checkEmailExists(email, this.patient.tenantId)
            .pipe(catchError(err => of(err)))
        )
      )
      .subscribe(res => {
        if (res.status === 200) {
          const exists = this.patient.user.email !== res.email;
          emailForm.setErrors(exists ? { exists } : null);
        } else if (res.status === 404) {
          emailForm.setErrors(null);
        } else if (res.status === 400) {
          emailForm.setErrors({ invalid: true });
        }
      });

    this.form.get('emailContact').valueChanges.subscribe(email => {
      this.form.get('emailContact').setValue(email && email.toLowerCase(), { emitEvent: false });
    });
  }

  private patchForm() {
    this.form.patchValue(this.patient);
    if (this.patient.user) {
      this.form.get('email').setValue(this.patient.user.email);
      this.form.get('emailContact').setValue(this.patient.user.emailContact);
    }
    const date = new Date(this.patient.dateOfBirth);
    this.form.get('dateOfBirth').setValue(date.toISOString().substring(0, 10));

    if (this.isDependent) {
      this.form.get('responsible').setValue(this.patient.responsible._id);
      this.form.removeControl('address');
      this.form.removeControl('cellphone');
      this.form.removeControl('telephone');
    } else {
      this.form.get('cpf').setValidators([Validators.required, ValidationService.cpfValidator]);
      if (this.hasAccount) {
        this.form.get('email').setValidators([Validators.required, Validators.email]);
      } else {
        this.form.get('email').setValidators(Validators.email);
      }
    }
  }

  private disableInputs() {
    this.form.get('cpf').disable();
    this.form.get('dateOfBirth').disable();

    if (this.form.get('fullname').value) {
      this.form.get('fullname').disable();
    }

    if (this.hasAccount) {
      this.form.get('email').disable();
      this.form.get('sex').disable();
      this.form.get('membership.mothersName').disable();
    }
  }

  get cpfCtrl() {
    return this.form.get('cpf');
  }

  get dateCtrl() {
    return this.form.get('dateOfBirth');
  }

  get currentDate() {
    const now = new Date();
    const dd = String(now.getDate()).padStart(2, '0');
    const mm = String(now.getMonth() + 1).padStart(2, '0');
    const yyyy = now.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  }

  get hasAccount() {
    return this.patient.user && !this.patient.user.activationToken && this.patient.user.email;
  }

  get isDependent() {
    return this.patient.responsible;
  }

  checkRepeatName(value: boolean) {
    if (value) {
      this.form.get('name').setValue(this.form.get('fullname').value);
      this.form.get('name').disable();
    } else {
      this.form.get('name').enable();
    }
  }

  async onKey(event: any) {
    if (event.target.value.length === 10) {
      const cep = event.target.value.replace(/[^\d]+/g, '');
      const data = await this.cepService.consult(cep);
      if (!data.erro) {
        this.form.controls['address'].setValue({
          uf: data.uf,
          street: data.logradouro,
          neighborhood: data.bairro,
          city: data.localidade,
          complement: data.complemento,
          number: null,
          cep: data.cep
        });
      }
    }
  }

  submitForm() {
    markFormGroup(this.form);
    const form = this.form.getRawValue();

    if (this.form.valid) {
      this.submit.emit(form);
      this.close();
    }
  }

  close() {
    this.modalRef.hide();
  }
}
