import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxMaskModule } from 'ngx-mask';
import { EntryModule } from '../entry/entry.module';
import { SimplifiedPatientFormComponent } from './components/simplified-patient-form/simplified-patient-form.component';
import { SimplifiedUnderagePatientFormComponent } from './components/simplified-underage-patient-form/simplified-underage-patient-form.component';
import { IndexComponent } from './pages/index/index.component';
import { ModalAddPatientComponent } from './pages/modal-add-patient/modal-add-patient.component';
import { ModalEditPatientComponent } from './pages/modal-edit-patient/modal-edit-patient.component';
import { ModalSearchComponent } from './pages/modal-search/modal-search.component';
import { PatientDetailsComponent } from './pages/patient-details/patient-details.component';
import { FloatButtonComponent } from './pages/patient-record/float-button/float-button.component';
import { PatientRecordComponent } from './pages/patient-record/patient-record.component';
import { RecordCardComponent } from './pages/patient-record/record-card/record-card.component';
import { PatientsRoutingModule } from './patients-routing.module';
import { SimplifiedPetPatientFormComponent } from './components/simplified-pet-patient-form/simplified-pet-patient-form.component';
import { ModalSelectPetComponent } from './pages/modal-select-pet/modal-select-pet.component';
import { ModalEditPetComponent } from './pages/modal-edit-pet/modal-edit-pet.component';

@NgModule({
  imports: [
    SharedModule,
    PatientsRoutingModule,
    NgxLoadingModule.forRoot({}),
    EntryModule,
    ModalModule.forRoot(),
    NzSpinModule,
    NzListModule,
    NgxMaskModule.forRoot(),
    NzCheckboxModule,
    ScrollingModule
  ],
  declarations: [
    IndexComponent,
    PatientDetailsComponent,
    ModalSearchComponent,
    ModalAddPatientComponent,
    PatientRecordComponent,
    ModalEditPatientComponent,
    RecordCardComponent,
    FloatButtonComponent,
    SimplifiedPatientFormComponent,
    SimplifiedUnderagePatientFormComponent,
    SimplifiedPetPatientFormComponent,
    ModalSelectPetComponent,
    ModalEditPetComponent
  ],
  exports: [PatientRecordComponent, FloatButtonComponent],
  entryComponents: [
    ModalSearchComponent,
    ModalAddPatientComponent,
    ModalEditPatientComponent,
    ModalEditPetComponent
  ]
})
export class PatientsModule {}
