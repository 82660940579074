import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { HealthProfessionalService } from '@app/modules/health-professional/services/health-professional.service';
import { ModalAddPatientComponent } from '@app/modules/patients/pages/modal-add-patient/modal-add-patient.component';
import { ModalSearchComponent } from '@app/modules/patients/pages/modal-search/modal-search.component';
import { PatientDetailsComponent } from '@app/modules/patients/pages/patient-details/patient-details.component';
import { HealthProfessional, Patient } from '@app/shared/models';
import { User } from '@app/shared/models/decodedLoginToken';
import { NzModalService } from 'ng-zorro-antd';
import { BsModalService } from 'ngx-bootstrap/modal';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ModalSelectPetComponent } from '../modal-select-pet/modal-select-pet.component';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  userId: string;
  allChecked = false;
  disabledButton = true;
  checkedNumber = 0;
  displayData: Array<{ name: string; age: number; address: string; checked: boolean }> = [];
  operating = false;
  dataSet: Patient[] = [];
  patient: Patient[];
  prescriber: HealthProfessional;
  loading: boolean;
  searchForm: FormGroup;
  indeterminate = false;
  totalRecord: 0;
  pageSize = 10;
  skip = 0;
  query: string = null;

  @ViewChild(PatientDetailsComponent, { static: true })
  modalPatient: PatientDetailsComponent;

  scrollConfig = { x: '830px', y: '583px' };

  constructor(
    private router: Router,
    private healthProfessionalService: HealthProfessionalService,
    private authService: AuthService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private nzModalService: NzModalService
  ) {}

  async ngOnInit(): Promise<void> {
    this.initForm();
    this.userId = this.user._id;
    this.getHealthProfessionalData(this.user._id);
  }

  get user(): User {
    return this.authService.user();
  }

  private get isVeterinarian(): boolean {
    return this.prescriber.professionalType === 'veterinarian';
  }

  async getHealthProfessionalData(userId: string) {
    this.loading = true;
    this.prescriber = await this.healthProfessionalService.getHealthProfessional(userId).toPromise();

    this.getPatients(this.query);
  }

  requestPatients() {
    this.loading = true;
    this.healthProfessionalService
      .getPatientsByUserIdWithPagination(this.query, this.user._id, this.pageSize, this.skip)
      .subscribe(
        data => {
          this.dataSet = data.patients;
          this.totalRecord = data.totalRecord;
          this.loading = false;
        },
        err => {
          console.error(err);
          this.loading = false;
        }
      );
  }

  requestPetPatients() {
    this.loading = true;
    this.healthProfessionalService.getPetsWithPagination(this.query, this.pageSize, this.skip).subscribe(
      data => {
        this.dataSet = data.pets;
        this.totalRecord = data.totalRecord;
        this.loading = false;
      },
      err => {
        console.error(err);
        this.loading = false;
      }
    );
  }

  async showModalRemovePatient(patientId: string) {
    this.nzModalService.confirm({
      nzTitle: 'Alerta',
      nzContent: 'Tem certeza que deseja remover este paciente da sua lista',
      nzOnOk: async () => {
        if (this.isVeterinarian) {
          this.skip = 0;
          this.requestPetPatients();
          await this.healthProfessionalService
            .removePetPatient(this.prescriber._id, patientId)
            .toPromise();
        } else {
          this.skip = 0;
          this.requestPatients();
          await this.healthProfessionalService.removePatient(this.prescriber._id, patientId).toPromise();
        }
      }
    });
  }

  getPatients(query: string) {
    this.query = query;
    this.skip = 0;

    this.isVeterinarian ? this.requestPetPatients() : this.requestPatients();
  }

  getAllPatientsNames(allNames) {
    let result = '';
    for (let i = 0; i < allNames.length; i++) {
      result = this.dataSet[i].name;
    }
    return result;
  }

  private initForm() {
    this.searchForm = this.fb.group({
      query: this.fb.control('')
    });

    this.searchForm
      .get('query')
      .valueChanges.pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(value => this.getPatients(value));
  }

  clearQuery() {
    this.searchForm.get('query').setValue('');
  }

  currentPageDataChange(
    $event: Array<{ name: string; age: number; address: string; checked: boolean }>
  ): void {
    this.displayData = $event;
  }

  refreshStatus(): void {
    const allChecked = this.displayData.every(value => value.checked);
    const allUnChecked = this.displayData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = !allChecked && !allUnChecked;
  }

  checkAll(value: boolean): void {
    this.displayData.forEach(data => (data.checked = value));
    this.refreshStatus();
  }

  togglePatientModalDetails(event: Event, patient: any): void {
    event.preventDefault();
    this.modalPatient.createTplModal(patient, this.prescriber);
    this.modalPatient.update.subscribe(saved =>
      this.isVeterinarian ? this.requestPetPatients() : this.requestPatients()
    );
  }

  redirectToPatientPrescriptions(patientId: string) {
    this.router.navigate(['historic'], { queryParams: { patientId } });
  }

  redirectToNewPrescription(patient) {
    localStorage.setItem('patient', JSON.stringify(patient));
    const url = this.isVeterinarian ? '/document/new' : '/prescription/new';
    this.router.navigate([url]);
  }

  showModalSearch(cpf?: string) {
    const initialState = {
      user: this.user,
      prescriber: this.prescriber,
      addPatient: true,
      cpf
    };
    const modal = this.modalService.show(ModalSearchComponent, {
      initialState,
      backdrop: 'static',
      keyboard: false
    });
    modal.content.updateListPatientEvent.subscribe(patient => {
      this.query = null;
      this.skip = 0;
      this.requestPatients();
    });
    modal.content.openModalAddPatientEvent.subscribe(consult => this.showModalAddPatient(consult));
    modal.content.openModalSelectPetEvent.subscribe(consult => this.showModalSelectPet(consult));
    modal.content.loadingSpinnerEvent.subscribe(loading => (this.loading = loading));

    if (cpf) {
      setInterval(() => modal.hide(), 1);
    }
  }

  private showModalAddPatient(consult) {
    const initialState = {
      prescriber: this.prescriber,
      patient: consult.patient,
      isResponsible: consult.isResponsible,
      existingPatient: consult.existingPatient
    };
    const modal = this.modalService.show(ModalAddPatientComponent, {
      class: 'modal-lg',
      initialState,
      backdrop: 'static',
      keyboard: false
    });
    modal.content.updateListPatientEvent.subscribe(_ => {
      this.query = null;
      this.skip = 0;
      this.requestPatients();
    });
  }

  private showModalSelectPet(consult) {
    const initialState = {
      prescriber: this.prescriber,
      pets: consult.patient.pets,
      responsible: consult.patient
    };
    const modal = this.modalService.show(ModalSelectPetComponent, {
      initialState,
      backdrop: 'static',
      keyboard: false
    });

    modal.content.submit.subscribe(_ => {
      this.query = null;
      this.skip = 0;
      this.requestPetPatients();
      this.modalService.hide(1);
    });
  }

  requestMorePatients(pageIndex: number): void {
    this.loading = true;
    this.skip = this.pageSize * (pageIndex - 1);
    this.isVeterinarian ? this.requestPetPatients() : this.requestPatients();
  }
}
