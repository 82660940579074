<ng-template #tplContent>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h1 class="title" [ngStyle]="{ color: colorSetting?.primary }">
      {{ title }}
    </h1>
    <p>Código do documento: {{ code }}</p>
  </div>

  <div class="container send-document" [formGroup]="sendForm" theme *ngIf="!isRennova">
    <p>Seu paciente já pode consultar esse documento no Portal do Paciente!</p>
    <h6 [ngStyle]="{ color: colorSetting?.secondary }">
      <span *ngIf="sendDocumentRequired; else notRequired">
        Selecione ao menos uma opção para {{ resend ? 'reenviar' : 'enviar' }} o link para o paciente.
      </span>
      <ng-template #notRequired>
        Deseja {{ resend ? 'reenviar' : 'enviar' }} o link para o paciente?
      </ng-template>
    </h6>

    <ng-template #addOnBeforeTemplateEmail>
      <label nz-checkbox formControlName="emailChecked">E-mail</label>
    </ng-template>
    <ng-template #addOnBeforeTemplateSms>
      <label nz-checkbox formControlName="smsChecked">SMS</label>
    </ng-template>
    <ng-template #addOnBeforeTemplateWhatsapp>
      <label nz-checkbox formControlName="whatsappChecked">WhatsApp</label>
    </ng-template>
    <div class="inputs responsive">
      <nz-form-item>
        <nz-form-control>
          <nz-input-group [nzAddOnBefore]="addOnBeforeTemplateEmail">
            <input
              nz-input
              formControlName="email"
              placeholder="Digite um e-mail"
              [ngStyle]="{
                'background-color': colorSetting?.inputBackground,
                'border-color': colorSetting?.inputBorder,
                color: colorSetting?.primary
              }"
            />
          </nz-input-group>
          <nz-form-explain *ngIf="email.dirty && email.errors">
            <ng-container *ngIf="email.hasError('requiredEmail')">
              Informe um e-mail para envio
            </ng-container>
            <ng-container *ngIf="!email.hasError('requiredEmail') && email.invalid">
              E-mail inválido, por favor digite um e-mail válido.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-control>
          <nz-input-group [nzAddOnBefore]="addOnBeforeTemplateSms">
            <input
              nz-input
              formControlName="sms"
              mask="(00) 00000-0000"
              placeholder="Digite um número de celular"
              [ngStyle]="{
                'background-color': colorSetting?.inputBackground,
                'border-color': colorSetting?.inputBorder,
                color: colorSetting?.primary
              }"
            />
          </nz-input-group>
          <nz-form-explain *ngIf="sms.dirty && sms.errors">
            <ng-container *ngIf="sms.hasError('requiredSms')">
              Informe um telefone celular para envio
            </ng-container>
            <ng-container *ngIf="!sms.hasError('requiredSms') && sms.hasError('celular')">
              Celular inválido, por favor digite um número válido.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-control>
          <nz-input-group [nzAddOnBefore]="addOnBeforeTemplateWhatsapp">
            <input
              nz-input
              formControlName="whatsapp"
              mask="(00) 00000-0000"
              placeholder="Digite um número de celular"
              [ngStyle]="{
                'background-color': colorSetting?.inputBackground,
                'border-color': colorSetting?.inputBorder,
                color: colorSetting?.primary
              }"
            />
          </nz-input-group>
          <nz-form-explain *ngIf="whatsapp.dirty && whatsapp.errors">
            <ng-container *ngIf="whatsapp.hasError('requiredWhatsapp')">
              Informe um telefone celular para envio
            </ng-container>
            <ng-container *ngIf="!whatsapp.hasError('requiredWhatsapp') && whatsapp.hasError('celular')">
              Celular inválido, por favor digite um número válido.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div class="d-flex flex-column justify-content-start align-items-start">
    <h6 class="d-block" *ngIf="showDownloadSignedPdf">
      Para baixar o PDF assinado,
      <a class="link" href="javascript:;" (click)="downloadPrescriptionPdf(code)">clique aqui!</a>
    </h6>
    <div class="row">
      <div class="col-6-md pl-3">
        Link de acesso ao documento:
      </div>
      <div class="col-6-md pl-md-1 pl-3">
        <a class="link" [href]="linkAcesso" target="_blank">{{ linkAcesso }}</a>
        <i
          (click)="copyLinkAccess()"
          style="cursor: pointer;"
          class="ml-2"
          nz-icon
          nzType="copy"
          nzTheme="outline"
        ></i>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #tplFooter>
  <div
    class="container d-flex align-items-end flex-column modal-footer"
    theme
    [ngStyle]="{
      'background-color': colorSetting?.secondary,
      color: colorSetting?.secondary
    }"
  >
    <div class="row">
      <button
        (click)="isRennova ? sendRennova() : send()"
        class="btn btn-ok"
        [disabled]="
          !sendForm.valid || (sendDocumentRequired && !emailChecked && !smsChecked && !whatsappChecked)
        "
        [ngStyle]="{
          'background-color': colorSetting?.primary,
          'border-color': colorSetting?.primary
        }"
      >
        Finalizar
      </button>
    </div>
  </div>
</ng-template>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
