import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '@app/core/services/validation.service';
import { BuyerService } from '@app/modules/document/services/buyer.service';
import { CepService } from '@app/modules/entry/services/cep.service';
import { PatientsService } from '@app/modules/patients/services/patients.service';
import { BrazilianStates } from '@app/shared/data/Brazilian-states';
import { BrazilState } from '@app/shared/models';
import { CpfCnpjApiErrors } from '@app/shared/models/cpf-cnpj-api-errors.enum';
import { Patient } from '@app/shared/models/patient';
import { markFormGroup } from '@app/utils/markFormGroup';
import { NzModalRef, NzNotificationService } from 'ng-zorro-antd';
import { BigboostService } from './../../../../shared/services/bigboost.service';

@Component({
  selector: 'app-modal-buyer-data',
  templateUrl: './modal-buyer-data.component.html',
  styleUrls: ['./modal-buyer-data.component.scss']
})
export class ModalBuyerDataComponent implements OnInit {
  isSavedBuyer = false;
  states: BrazilState[] = BrazilianStates;
  loading = false;

  @Input() patient: Patient;
  @Input() okText = 'Salvar';
  @Output() saveRetention = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private cepService: CepService,
    private bigboostService: BigboostService,
    private notification: NzNotificationService,
    private buyerService: BuyerService,
    private patientsService: PatientsService,
    private ref: NzModalRef
  ) {}

  form: FormGroup = this.fb.group({
    isPatientBuyer: this.fb.control(false),
    name: this.fb.control(
      '',
      Validators.compose([Validators.required, ValidationService.nomeValidator])
    ),
    cpf: this.fb.control('', [Validators.required, ValidationService.cpfValidator]),
    identity: this.fb.control('', Validators.required),
    cellphone: this.fb.control(null, Validators.required),
    telephone: this.fb.control(null),
    address: this.fb.group({
      uf: this.fb.control(null, Validators.required),
      city: this.fb.control('', Validators.required),
      complement: this.fb.control(''),
      number: this.fb.control('', Validators.required),
      street: this.fb.control('', Validators.required),
      neighborhood: this.fb.control('', Validators.required),
      cep: this.fb.control('', Validators.compose([Validators.required, ValidationService.cepValidator]))
    })
  });

  ngOnInit() {}

  get isPatientBuyer() {
    return this.form.get('isPatientBuyer').value;
  }

  set isPatientBuyer(value: boolean) {
    this.form.get('isPatientBuyer').setValue(value);
  }

  async onKeyFindCep(event: any) {
    if (!this.isSavedBuyer) {
      if (event.target.value.length === 10) {
        const cep = event.target.value.replace(/[^\d]+/g, '');
        const data = await this.cepService.consult(cep);
        if (!data.erro) {
          this.form.controls['address'].setValue({
            uf: data.uf,
            street: data.logradouro,
            neighborhood: data.bairro,
            city: data.localidade,
            complement: data.complemento,
            number: null,
            cep: data.cep
          });
        }
      }
    }
  }

  async onKeyFindBuyer(event: any) {
    try {
      const cpf = event.target.value.replace(/[^\d]+/g, '');
      if (this.isSavedBuyer) {
        this.isSavedBuyer = false;
        this.form.enable();
        this.form.reset();
      } else if (cpf.length === 11) {
        this.loading = true;
        const buyer = await this.buyerService.getBuyerByCpf(cpf).toPromise();
        if (buyer) {
          this.isSavedBuyer = true;
          this.setValues(buyer);
          this.disableForm();
        } else {
          this.isSavedBuyer = false;
        }
      }
    } catch (err) {
      console.warn(err);
    }
    this.loading = false;
  }

  setDataPatient(isPatientBuyer: boolean) {
    this.isSavedBuyer = false;
    if (isPatientBuyer) {
      this.setValues(this.patient);
      if (this.patient._id) {
        this.disableForm();
      }
      if (!this.patient.identity) {
        this.form.get('identity').enable();
      }
    } else {
      this.form.enable();
    }
  }

  private setValues(data) {
    this.form.markAllAsTouched();
    this.form.patchValue(data);
  }

  private disableForm() {
    this.form.disable();
    this.form.get('isPatientBuyer').enable();
    this.form.get('cpf').enable();
  }

  setMask(mask, value) {
    if (value !== undefined) {
      const array = value.split('');
      array.forEach(c => {
        mask = mask.replace('#', c);
      });
      return mask;
    }
    return '';
  }

  async save() {
    try {
      if (this.isSavedBuyer) {
        this.saveRetention.emit(this.form.value.cpf);
      } else if (this.isPatientBuyer && this.patient._id) {
        if (!this.form.get('identity').value) {
          this.notification.warning(
            'Aviso',
            'Este paciente foi incluido no sistema sem o campo identidade, para continuar deve ser informado.'
          );
          return;
        }
        this.loading = true;
        await this.patientsService
          .update(this.patient._id, { identity: this.form.get('identity').value })
          .toPromise();
        this.loading = false;
        this.saveRetention.emit(this.patient.cpf);
      } else {
        markFormGroup(this.form);
        if (this.form.valid && !this.loading) {
          this.loading = true;
          this.form.disable();
          const isValid = await this.isValidCpf(this.form.value.cpf);
          if (!isValid) {
            this.form.enable();
            return;
          } else {
            const buyer = await this.buyerService.save(this.form.value).toPromise();
            if (buyer) {
              this.saveRetention.emit(buyer.cpf);
            }
          }
          this.loading = false;
          this.form.enable();
        }
      }
    } catch (err) {
      this.form.disable();
      this.loading = false;
    }
  }

  async isValidCpf(cpf) {
    try {
      this.loading = true;
      const consult = await this.bigboostService.consultCpf(cpf).toPromise();
      this.loading = false;
      if (consult.status === 1) {
        return true;
      }
      if (consult.status === -1 || consult.erroCodigo >= 1000) {
        consult.erro =
          'A importação de dados do CPF está com instabilidade, este cpf ficará pendente de validação.';
        this.form.value.pendency = consult;
        return true;
      }
      if (consult.status === 0 && consult.erroCodigo && consult.erroCodigo < 1000) {
        if (Object.values(CpfCnpjApiErrors).includes(consult.erroCodigo)) {
          this.notification.warning('Aviso', consult.erro);
          return false;
        }
      }
      return false;
    } catch (error) {
      this.loading = false;
    }
  }

  close() {
    this.ref.close();
  }
}
