<app-alternative-navbar title="Meus dados" titleIcon="fa fa-account-box"></app-alternative-navbar>
<app-sidebar-menu></app-sidebar-menu>
<ng-container [ngSwitch]="user?.userType">
  <section
    class="section profile section-shaped bg-white page-header"
    *ngIf="user?.userType !== 'pharmacy'"
  >
    <div class="container">
      <div class="row d-flex flex-column">
        <div class="col-md-12">
          <div class="media _mb-10" *ngIf="completeUser">
            <ngx-photo-editor
              [imageChanedEvent]="imageChangedEvent"
              (imageCropped)="imageCropped($event)"
              [darkTheme]="false"
              [autoCrop]="true"
              [modalCentered]="true"
              [roundCropper]="true"
              [aspectRatio]="1"
              [viewMode]="2"
              [resizeToWidth]="1000"
            ></ngx-photo-editor>

            <div>
              <label for="avatar-input" class="input-label">
                <ngx-avatar
                  *ngIf="avatarB64"
                  class="mr-3 avatar"
                  [src]="avatarB64"
                  [name]="completeUser.name"
                  size="100"
                  [style.width]="'100px'"
                  [style.height]="'100px'"
                ></ngx-avatar>
                <div class="icon-edit-profile">
                  <img src="assets/icons/pen-solid.svg" class="svg" />
                </div>
                <img
                  class="mr-3 avatar"
                  src="assets/img/default-avatar.png"
                  alt="Generic placeholder image"
                  *ngIf="!avatarB64 && completeUser.sex !== 'F'"
                />

                <img
                  class="mr-3 avatar"
                  src="assets/img/default-avatar.F.png"
                  alt="Generic placeholder image"
                  *ngIf="!avatarB64 && completeUser.sex === 'F'"
                />
              </label>
              <input
                type="file"
                id="avatar-input"
                (change)="fileChangeEvent($event)"
                accept=".jpg,.jpeg,.png"
              />
            </div>

            <div class="media-body pl-2" *ngIf="completeUser">
              <h5 class="mt-0" *ngSwitchCase="'healthProfessional'">Olá, {{ doctorName }}</h5>
              <h5 class="mt-0" *ngSwitchCase="'patient'">Olá, {{ completeUser.name }}</h5>
              <h5 class="mt-0" *ngSwitchCase="'pharmacist'">Olá, {{ completeUser.name }}</h5>
              <h5 class="mt-0" *ngSwitchCase="'pharmacy'">Olá, {{ completeUser.fantasyName }}</h5>
              <p class="date">{{ today | date: 'fullDate' }} | {{ today | date: 'HH:mm' }}</p>
              <p class="crm" *ngSwitchCase="'healthProfessional'">
                {{ completeUser.regionalCouncilNumber.label }}:
                {{ completeUser.regionalCouncilNumber.number }}/{{
                  completeUser.regionalCouncilNumber.uf
                }}
              </p>
              <p class="crm" *ngSwitchCase="'pharmacist'">
                CRF:
                {{ completeUser.crf.number }}/{{ completeUser.crf.uf }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="my-data profile">
    <div class="container data" *ngIf="completeUser && user">
      <div class="content">
        <div class="title-area">
          <p class="title">DADOS PESSOAIS</p>
          <button
            *ngIf="user.userType !== 'pharmacy'"
            class="edit-button"
            (click)="showModalPersonalData()"
          >
            <img src="assets/icons/pen-solid.svg" class="svg" />
          </button>
          <button
            *ngIf="user.userType === 'pharmacy'"
            class="edit-button"
            (click)="showModalPersonalData()"
          >
            <img src="assets/icons/pen-solid.svg" class="svg" />
          </button>
        </div>
        <div class="row">
          <div class="col-md">
            <span class="info-area" *ngIf="completeUser.cellphone">
              <img
                [src]="
                  completeUser.cellphone.length > 10
                    ? 'assets/icons/cellphone-alt-solid.svg'
                    : 'assets/icons/phone-alt-solid.svg'
                "
                class="svg"
              />
              <h5>{{ completeUser.cellphone | formattedTel }}</h5>
            </span>

            <span class="info-area" *ngIf="completeUser.telephone">
              <img
                [src]="
                  completeUser.telephone.length > 10
                    ? 'assets/icons/cellphone-alt-solid.svg'
                    : 'assets/icons/phone-alt-solid.svg'
                "
                class="svg"
              />
              <h5>{{ completeUser.telephone | formattedCel }}</h5>
            </span>

            <span class="info-area">
              <img src="assets/icons/user-alt-solid.svg" class="svg" title="E-mail de login" />
              <h5>{{ completeUser.user?.email }}</h5>
            </span>

            <span
              class="info-area"
              *ngIf="
                completeUser.user?.emailContact &&
                completeUser.user?.emailContact !== completeUser.user?.email
              "
            >
              <img src="assets/icons/envelope-solid.svg" class="svg" title="E-mail de contato" />
              <h5>{{ completeUser.user?.emailContact }}</h5>
            </span>

            <span *ngIf="user.userType === 'healthProfessional'" class="info-area">
              <img src="assets/icons/user-md-solid.svg" class="svg" />
              <h5>{{ specialties }}</h5>
            </span>
          </div>

          <div class="col-md">
            <span class="info-area" *ngIf="completeUser.dateOfBirth">
              <i class="fa fa-birthday-cake-solid"></i>
              <h5>{{ completeUser.dateOfBirth | date: 'dd/MM/yyyy':'GMT' }}</h5>
            </span>

            <span class="info-area">
              <img src="assets/icons/id-card-alt-solid.svg" class="svg" />
              <h5 *ngSwitchCase="'healthProfessional'">
                {{ completeUser.regionalCouncilNumber.label }}:
                {{ completeUser.regionalCouncilNumber.number }}
              </h5>
              <h5 *ngSwitchCase="'patient'">Identidade: {{ completeUser.identity }}</h5>
              <h5 *ngSwitchCase="'pharmacist'">Identidade: {{ completeUser.identity }}</h5>
              <h5 *ngSwitchCase="'pharmacy'">CNPJ: {{ completeUser.cnpj | formattedCnpj }}</h5>
            </span>

            <span *ngIf="user.userType === 'patient' && completeUser.sex" class="info-area">
              <i *ngIf="completeUser.sex === 'F'" class="fa fa-venus"></i>
              <i *ngIf="completeUser.sex === 'M'" class="fa fa-mars"></i>
              <h5>{{ completeUser.sex === 'F' ? 'Feminino' : 'Masculino' }}</h5>
            </span>

            <span *ngIf="completeUser.professionalType === 'veterinarian'" class="info-area">
              <img src="assets/icons/id-card-alt-solid.svg" class="svg" />

              <h5 *ngIf="user.userType != 'pharmacy'">
                MAPA Sipeagro:
                {{
                  completeUser.mapaSipeagro
                    ? (completeUser.mapaSipeagro | formattedMapaSipeagro)
                    : 'Não informado'
                }}
              </h5>
            </span>

            <span class="cpf info-area">
              <img src="assets/icons/address-card-solid.svg" class="svg" />
              <h5 *ngSwitchCase="'pharmacy'">
                CPF (responsável legal): {{ completeUser.cpfResponsible | formattedCpf }}
              </h5>
              <h5 *ngIf="user.userType != 'pharmacy'">CPF: {{ completeUser.cpf | formattedCpf }}</h5>
            </span>
          </div>
        </div>
      </div>

      <div class="content" *ngIf="user.userType === 'healthProfessional'">
        <hr />
        <div class="title-area">
          <p class="title">DADOS EMPRESARIAIS</p>
        </div>
        <table>
          <ngx-photo-editor
            [imageChanedEvent]="logoChangedEvent"
            (imageCropped)="logoCropped($event)"
            [imageUrl]="imageURL"
            [darkTheme]="false"
            [autoCrop]="false"
            [modalCentered]="false"
            [roundCropper]="false"
            [aspectRatio]="0"
            [viewMode]="1"
            [resizeToWidth]="300"
          ></ngx-photo-editor>
          <ng-container *ngFor="let data of completeUser.commercialData; index as i">
            <tr>
              <th>
                <img src="assets/icons/briefcase-solid.svg" />
              </th>
              <th>
                <h5 class="m-3">{{ data.name }}</h5>
              </th>
              <th>
                <a
                  *ngIf="data.logoUrl"
                  (click)="showLogo(data.logoUrl, data._id)"
                  title="Visualizar Logo"
                >
                  <img src="assets/icons/image-solid.svg" class="svg" />
                </a>
                <button id="button-edit-business" (click)="showModalBusinessData(false, data, i)">
                  <img src="assets/icons/pen-solid.svg" class="svg" />
                </button>
                <button
                  id="button-remove-business"
                  class="pt-1"
                  (click)="showModalRemoveBusinessData(i)"
                  *ngIf="completeUser.commercialData.length > 1"
                >
                  <i class="fa fa-trash-solid"></i>
                </button>
              </th>
            </tr>
            <tr>
              <th colspan="4" class="pb-3">
                <label *ngIf="!data.logoUrl" [for]="'logo' + i" class="input-label"
                  >Adicionar Logo</label
                >
                <label *ngIf="data.logoUrl" [for]="'logo' + i" class="input-label">Atualizar Logo</label>
                <label *ngIf="data.logoUrl" class="input-label" (click)="removeLogo(data._id)"
                  >&nbsp;| Excluir Logo</label
                >
                <app-popover-button
                  content="A imagem do logotipo deve ser um arquivo JPEG de tamanho máximo de 1MB. Dimensão sugerida 300x120 pixels."
                ></app-popover-button>
                <input
                  [id]="'logo' + i"
                  class="logo-img"
                  type="file"
                  (change)="addLogo($event, data._id)"
                  accept=".jpg,.jpeg,.png"
                />
              </th>
            </tr>
          </ng-container>
        </table>

        <div class="add-data">
          <span (click)="showModalBusinessData(true)">
            Adicionar dados empresariais
          </span>
        </div>
      </div>

      <div class="content" *ngIf="user.userType !== 'healthProfessional'">
        <hr />
        <div *ngIf="!completeUser.address" class="add-data">
          <span class="add-data" (click)="showModalAddressData()">
            Adicionar Endereço
          </span>
        </div>
        <div class="title-area" *ngIf="completeUser.address">
          <div class="title-address">
            <img src="assets/icons/house-user-solid.svg" class="svg" />
            <p class="title">ENDEREÇO</p>
          </div>
          <button class="edit-button" (click)="showModalAddressData()">
            <img src="assets/icons/pen-solid.svg" class="svg" />
          </button>
        </div>

        <div class="row address" *ngIf="completeUser.address">
          <div class="col-md">
            <div class="address-info-area">
              <span class="address-title">CEP: &nbsp;</span>
              <h5>{{ completeUser.address.cep | mask: '00.000-000' }}</h5>
            </div>

            <div class="address-info-area">
              <span class="address-title">Logradouro: &nbsp;</span>
              <h5 class="info">
                {{ completeUser.address.street }}
              </h5>
            </div>

            <div class="address-info-area">
              <span class="address-title">Número: &nbsp;</span>
              <h5 class="info">
                {{ completeUser.address.number }}
              </h5>
            </div>

            <div class="address-info-area">
              <span class="address-title">Complemento: &nbsp;</span>
              <h5 *ngIf="completeUser.address.complement" class="info">
                {{ completeUser.address.complement }}
              </h5>
              <h5 *ngIf="!completeUser.address.complement">-</h5>
            </div>
          </div>
          <div class="col-md">
            <div class="address-info-area">
              <span class="address-title">Bairro: &nbsp;</span>
              <h5 class="info">
                {{ completeUser.address.neighborhood }}
              </h5>
            </div>

            <div class="address-info-area">
              <span class="address-title">Cidade: &nbsp;</span>
              <h5 class="info">
                {{ completeUser.address.city }}
              </h5>
            </div>

            <div class="address-info-area">
              <span class="address-title">UF: &nbsp;</span>
              <h5 class="info">
                {{ completeUser.address.uf }}
              </h5>
            </div>
          </div>
        </div>

        <div *ngSwitchCase="'patient'">
          <div *ngIf="completeUser.dependents?.length || completeUser.pets?.length">
            <hr />
            <div class="title-area">
              <div class="title-address">
                <img src="assets/icons/hand-holding-medical.svg" class="svg" />
                <p class="title">DEPENDENTES</p>
              </div>
            </div>
            <div class="row">
              <div class="mt-4 col">
                <p class="title">MENORES</p>
                <div *ngFor="let dependent of completeUser.dependents">
                  <span class="info-area">
                    <h5 class="mr-3">{{ dependent.name }}</h5>
                    <button class="edit-button" (click)="editDependent(dependent)">
                      <img src="assets/icons/pen-solid.svg" class="svg" />
                    </button>
                  </span>
                </div>
              </div>

              <div class="mt-4 col">
                <p class="title">PETS</p>
                <div *ngFor="let pet of completeUser.pets">
                  <span class="info-area">
                    <h5 class="mr-3">{{ pet.name }}</h5>
                    <button class="edit-button" (click)="showPetData(pet)">
                      <img src="assets/icons/file-lines-regular.svg" class="svg" />
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div class="mt-4">
            <app-add-button label="Adicionar dependente" (click)="addDependent($event)"></app-add-button>
          </div>
        </div>
      </div>

      <div class="content" *ngSwitchCase="'pharmacy'">
        <hr />
        <div class="title-area">
          <p class="title">DADOS DO RESPONSÁVEL TÉCNICO</p>
          <button class="edit-button">
            <img src="assets/icons/pen-solid.svg" class="svg" (click)="showModalPharmacyData()" />
          </button>
        </div>
        <div class="row">
          <div class="col" *ngIf="completeUser.technicalResponsible as technicalResponsible">
            <span class="info-area">
              <img src="assets/icons/user-alt-solid.svg" class="svg" />
              <h5>{{ technicalResponsible.name }}</h5>
            </span>
            <span class="info-area">
              <img src="assets/icons/address-card-solid.svg" class="svg" />
              <h5>{{ technicalResponsible.cpf | formattedCpf }}</h5>
            </span>
            <span class="info-area">
              <img src="assets/icons/id-card-alt-solid.svg" class="svg" />
              <h5>
                {{ technicalResponsible.crf.number }} -
                {{ technicalResponsible.crf.uf }}
              </h5>
            </span>
          </div>
        </div>

        <hr />

        <div class="title-area">
          <p class="title">DADOS ESPECÍFICOS</p>
          <button class="edit-button">
            <img src="assets/icons/pen-solid.svg" class="svg" (click)="showModalCovid()" />
          </button>
        </div>
        <div class="row">
          <div class="col">
            <span class="info-area">
              <img src="assets/icons/Covid-19 arte.png" class="svg" />
              <h5 *ngIf="completeUser.covid == 1" class="info">
                Sua farmácia realiza Teste COVID-19? Sim, realiza
              </h5>
              <h5 *ngIf="completeUser.covid == 2" class="info">
                Sua farmácia realiza Teste COVID-19? Não realiza
              </h5>
              <h5 *ngIf="completeUser.covid == 3" class="info">
                Sua farmácia realiza Teste COVID-19? Não realiza, mas gostaria de realizar
              </h5>
              <h5 *ngIf="!completeUser.covid" class="info">
                Sua farmácia realiza Teste COVID-19? Sem resposta
              </h5>
            </span>
          </div>
        </div>
      </div>

      <div
        class="content"
        *ngIf="this.cipFeature && (user.userType === 'pharmacy' || user.userType === 'pharmacist')"
      >
        <hr />

        <div class="title-area">
          <p class="title">TERMO DE CONSENTIMENTO PARA TRATAMENTO DE DADOS (PROJETO PILOTO)</p>
          <button class="edit-button">
            <img src="assets/icons/pen-solid.svg" class="svg" (click)="showModalCip()" />
          </button>
        </div>
        <div class="row">
          <div class="col">
            <p class="info-area">Aceito? {{ this.cipFeatureAccepted }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<app-modal-cip-acceptance
  [isVisible]="isModalCipVisible"
  (isVisibleChange)="changeCipVisibility($event)"
  [code]="this.cipFeature?.code"
></app-modal-cip-acceptance>

<nz-modal
  [nzVisible]="isPetDataVisible"
  nzTitle="Dados do Pet"
  (nzOnCancel)="closePetModal()"
  [nzFooter]="modalFooter"
>
  <ng-container>
    <p>Nome: {{ pet?.name | titlecase }}</p>
    <p>Espécie: {{ pet?.species | titlecase }}</p>
    <p>Raça: {{ pet?.race ? pet.race : 'Não informado' }}</p>
    <p>
      Data de Nascimento:
      {{ pet?.dateOfBirth ? (pet?.dateOfBirth | date: 'dd/MM/yyyy') : 'Não informado' }}
    </p>
    <p>Peso: {{ pet?.weight ? pet?.weight : 'Não informado' }}</p>
    <p>Pelagem: {{ pet?.fur ? pet?.fur : 'Não informado' }}</p>
    <p>Catração: {{ pet?.castration ? 'Sim' : 'Não' }}</p>
    <p>Temperamento: {{ pet?.temperament ? pet?.temperament : 'Não informado' }}</p>
    <p>Nº do microchip: {{ pet?.microchipNumber ? pet?.microchipNumber : 'Não informado' }}</p>
  </ng-container>
</nz-modal>

<ng-template #modalFooter>
  <button nz-button type="button" class="btn" (click)="closePetModal()">
    <span>Ok</span>
  </button>
</ng-template>
