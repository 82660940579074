import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { CoreService } from '@app/core/services/core.service';
import { PharmacistService } from '@app/modules/pharmacist/services/pharmacist.service';
import { ModalSearchComponent } from '@app/shared/modals/modal-search/modal-search.component';
import { AdditionalFeatureAccept } from '@app/shared/models';
import { User } from '@app/shared/models/decodedLoginToken';
import { StateService } from '@app/shared/services/state.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { IconMenuComponent } from '../icon-menu/icon-menu.component';
import { Permission } from './../../models/permission.enum';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent implements OnInit {
  modalRef: BsModalRef;
  visible = false;
  userId: string;
  userType: string;
  completeUser: any;
  functionalities = [];
  Permission = Permission;
  backendVersion$: Observable<string>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private modalService: BsModalService,
    private authService: AuthService,
    private pharmacistService: PharmacistService,
    private coreService: CoreService,
    private stateService: StateService
  ) {
    this.backendVersion$ = this.coreService.getBackendVersion();
    this.router.events.subscribe(() => this.closeMenu());
  }

  ngOnInit() {
    this.getCompleteUser(this.user);
    this.userId = localStorage.getItem('userId');
    this.userType = localStorage.getItem('userType');
    document.getElementById('siderbar-menu-blur').addEventListener('click', () => this.closeMenu());
  }

  get user(): User {
    return this.authService.user();
  }

  private getCompleteUser(user) {
    if (user.userType === 'pharmacist') {
      this.getPharmacistData();
    }
  }

  async getPharmacistData() {
    this.completeUser = await this.pharmacistService.getPharmacistByUserId(this.user._id).toPromise();
  }

  get frontendVersion(): string {
    return this.coreService.frontendVersion;
  }

  get hasFarmaRennovaFeature(): boolean {
    return this.user && this.user.features.some(f => f.name === 'Farma Rennova');
  }

  get hasRennovaFeature(): boolean {
    return this.user && this.user.features.some(f => f.name === 'Rennova');
  }

  private closeMenu() {
    const sidebarMenu = this.document.getElementById('siderbar-menu');
    if (sidebarMenu) {
      this.renderer.setStyle(sidebarMenu, 'left', '-330px');
    }
    const sidebarMenuBlur = this.document.getElementById('siderbar-menu-blur');
    if (sidebarMenuBlur) {
      this.renderer.setStyle(sidebarMenuBlur, 'left', '-100%');
    }
    IconMenuComponent.activeMenu = false;
  }

  close(): void {
    this.visible = false;
  }

  changeRoute(route: string, queryParams: any) {
    this.router.navigate(['/admin', route], { queryParams });
  }

  openSearch() {
    this.modalRef = this.modalService.show(ModalSearchComponent, {
      backdrop: 'static',
      keyboard: false
    });
  }

  hasAccessToManipulatedFunctionality() {
    return false && this.user && this.user.features.find(f => f.name === 'Manipulados');
  }

  get hasCipFeature() {
    const cipFeature = this.user && this.user.features.find(f => f.name === 'CIP');
    return cipFeature && cipFeature.accept === AdditionalFeatureAccept.YES;
  }

  redirectDocumentNew(isRennova: boolean) {
    this.stateService.data = { isRennova };
    this.router.navigateByUrl('/document/new');
  }
}
