import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CpfCnpjDirective } from './cpf-cnpj.directive';
import { TelephoneDirective } from './telephone.directive';
import { AutoSizeDirective } from './auto-size.directive';
import { FocusDirective } from './focus.directive';
import { PatientSearchDirective } from './patient-search.directive';
import { CepDirective } from './cep.directive';
import { DigitOnlyDirective } from './digit-only.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    CpfCnpjDirective,
    TelephoneDirective,
    AutoSizeDirective,
    FocusDirective,
    PatientSearchDirective,
    CepDirective,
    DigitOnlyDirective
  ],
  exports: [
    CpfCnpjDirective,
    TelephoneDirective,
    AutoSizeDirective,
    FocusDirective,
    PatientSearchDirective,
    CepDirective,
    DigitOnlyDirective
  ]
})
export class DirectivesModule {}
