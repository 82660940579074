import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/core/services/auth.service';
import { ValidationService } from '@app/core/services/validation.service';
import { PrescriptionService } from '@app/modules/document/services/prescription.service';
import { RetentionService } from '@app/modules/document/services/retention.service';
import { BrazilianStates } from '@app/shared/data/Brazilian-states';
import { BrazilState } from '@app/shared/models/brazil-state';
import { CpfCnpjApiErrors } from '@app/shared/models/cpf-cnpj-api-errors.enum';
import { User } from '@app/shared/models/decodedLoginToken';
import { BigboostService } from '@app/shared/services/bigboost.service';
import { markFormGroup } from '@app/utils/markFormGroup';
import * as moment from 'moment';
import { NzNotificationService } from 'ng-zorro-antd';
import { UploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-recipe',
  templateUrl: './recipe.component.html',
  styleUrls: ['./recipe.component.scss']
})
export class RecipeComponent implements OnInit {
  navTitle: string;
  loading: boolean;
  states: BrazilState[] = BrazilianStates;
  currenteDate: Date = new Date();
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private authService: AuthService,
    private retentionService: RetentionService,
    private bigboostService: BigboostService,
    private prescriptionService: PrescriptionService
  ) {
    this.form = this.fb.group({
      dispensationDate: [moment().format('YYYY-MM-DD'), Validators.required],
      cpfBuyer: ['', [Validators.required, ValidationService.cpfValidator]],
      pdf: ['', Validators.required],
      preWriterRegister: this.fb.group({
        number: ['', Validators.required],
        uf: [null, Validators.required]
      }),
      pharmacyId: [null]
    });

    const pdf = this.prescriptionService.pdfBase64;
    if (pdf) {
      this.form.get('pdf').setValue(pdf);
    }
  }

  async ngOnInit() {
    this.navTitle = 'Receita de terceiros';

    const extraData = JSON.parse(localStorage.getItem('extra_data'));
    if (extraData) {
      this.form.get('pharmacyId').setValue(extraData.pharmacyId);
    }
  }

  get user(): User {
    return this.authService.user();
  }

  get file(): UploadFile {
    return this.form.get('pdf').value;
  }

  get filename(): string {
    return this.prescriptionService.filename;
  }

  set filename(value: string) {
    this.prescriptionService.filename = value;
  }

  async save() {
    markFormGroup(this.form);
    try {
      if (this.form.valid) {
        this.loading = true;
        const consult = await this.bigboostService.consultCpf(this.form.value.cpfBuyer).toPromise();
        if (consult.erroCodigo) {
          this.loading = false;
          if (!Object.values(CpfCnpjApiErrors).includes(consult.erroCodigo)) {
            this.notification.warning('Aviso', consult.erro);
          } else {
            this.notification.warning(
              'Aviso',
              'Estamos com problemas no momento, informe o suporte do sistema e tente novamente dentro de alguns minutos.'
            );
          }
          return;
        }

        const userId = this.user._id;
        await this.retentionService.saveRetentionOfThirdParties({
          userId,
          ...this.form.value
        });
        this.loading = false;
        this.reset();
        this.notification.success('Dados adicionados com sucesso', null);
      }
    } catch (err) {
      this.loading = false;
      if (err.status === 422) {
        this.notification.warning(
          'Aviso',
          'Este arquivo não é um PDF assinado digitalmente. Por favor, selecione um arquivo diferente.'
        );
      } else if (err.status === 400) {
        this.notification.warning(
          'Aviso',
          'Apenas permitido receitas gerados fora da plataforma Receita Digital. Tente realizar a dispensação pelo menu "Dispensar"'
        );
      }
    }
  }

  reset() {
    this.form.get('cpfBuyer').reset();
    this.form.get('pdf').reset();
    this.form.get('preWriterRegister').reset();
  }
}
