import { Address } from './address';
import { Comercial } from './comercial';
import { Specialty } from './specialty';
import { User } from './decodedLoginToken';
import { ProfessionalType } from './professional-type.enum';

export class HealthProfessional {
  _id: string;
  userId: string;
  user: User;
  professionalType: ProfessionalType;
  name: string;
  cpf: string;
  regionalCouncilNumber: {
    label: string;
    number: string;
    uf: string;
  };
  specialties: Specialty[];
  cellphone: string;
  telephone: string;
  otherPhones?: string[];
  otherEmails?: string[];
  personalAddress?: Address;
  dateOfBirth?: Date;
  sex?: string;
  commercialData?: Comercial[];
  pets?: any[];
  cfm?: any;

  loading?: boolean;

  static getRegionalCouncilLabel(professionalType: string) {
    const RegionalCouncilLabels = {
      dentist: 'CRO',
      doctor: 'CRM',
      pharmacist: 'CRF',
      biomedic: 'CRBM1',
      veterinarian: 'CRMV'
    };
    return RegionalCouncilLabels[professionalType];
  }

  static getProfessionalTypeName(professionalType: string) {
    const RegionalCouncilLabels = {
      dentist: 'Dentista',
      doctor: 'Médico',
      pharmacist: 'Farmacêutico',
      biomedic: 'Biomédico',
      veterinarian: 'Veterinário'
    };
    return RegionalCouncilLabels[professionalType];
  }

  static getProfessionalTypeNameLabel(professionalType: string) {
    const RegionalCouncilLabels = {
      dentist: 'Dentista',
      doctor: 'Médico(a)',
      pharmacist: 'Farmacêutico',
      biomedic: 'Biomédico',
      veterinarian: 'Veterinário(a)'
    };
    return RegionalCouncilLabels[professionalType];
  }
}
