<div class="page-content">
  <app-alternative-navbar></app-alternative-navbar>
  <app-sidebar-menu></app-sidebar-menu>
  <app-float-button *ngIf="patientRecordFeature"></app-float-button>

  <section class="section container-fluid prescription d-flex flex-column">
    <div class="row _p-20">
      <div class="hospital-brand doc-type">
        <nz-dropdown *ngIf="!document?.code && !modelId" nzTrigger="click">
          <a nz-dropdown><i class="fa fa-caret-circle-solid"></i></a>
          <ul nz-menu nzSelectable>
            <div *ngFor="let docType of typesOfDocumentsOptions">
              <li nz-menu-item (click)="changeDocumentType(docType)">
                <a>{{ docType }}</a>
              </li>
            </div>
          </ul>
        </nz-dropdown>
        <h4 class="hospital-name"><span *ngIf="modelId">Editar modelo de </span>{{ documentType }}</h4>
      </div>

      <div class="hospital-brand" *ngIf="!modelId">
        <nz-dropdown nzTrigger="click">
          <a nz-dropdown><i class="fa fa-caret-circle-solid"></i></a>
          <ul nz-menu nzSelectable>
            <div *ngFor="let address of prescriber?.commercialData">
              <li nz-menu-item (click)="changeLocal(address)">
                <a>{{ address.name }}</a>
              </li>
            </div>
          </ul>
        </nz-dropdown>
        <h4 class="hospital-name">{{ local?.name }}</h4>
      </div>

      <div class="hospital-brand">
        <div class="add-prescription add-patient" *ngIf="!signed && !modelId">
          <button
            class="btn btn-icon btn-add-outiline text-capitalize"
            type="button"
            (click)="showModalSearch()"
          >
            <span class="btn-inner--text">Novo paciente</span>
            <span class="btn-inner--icon"><i class="fa fa-plus"></i></span>
          </button>
        </div>
      </div>
    </div>

    <form [formGroup]="patientForm">
      <ng-container [formGroup]="patientForm" *ngIf="!modelId">
        <nz-form-item>
          <nz-form-control>
            <input
              id="cpfPatient"
              [nzAutocomplete]="autoName"
              appPatientSearch
              class="input-name"
              formControlName="name"
              maxlength="128"
              nz-input
              [placeholder]="
                isVeterinarian
                  ? 'Digite o CPF do responsável para buscar'
                  : 'Digite o CPF ou nome completo do paciente para buscar'
              "
              [disabled]="signed"
            />
            <nz-autocomplete #autoName nzBackfill>
              <nz-auto-option
                (click)="onChangePatient(option)"
                *ngFor="let option of patients"
                [nzLabel]="option.cpf !== '-1' ? option?.name || option?.fullname : ''"
                [nzValue]="option.cpf !== '-1' ? option?.name || option?.fullname : ''"
                class="mao"
              >
                {{ option?.name || option?.fullname }}
                {{ option?.cpf !== '-1' ? (option?.cpf | formattedCpf) : '' }}
                <span *ngIf="option?.responsible"
                  >&nbsp;({{ option.responsible.name || option.responsible.fullname }}
                  {{ option.responsible.cpf | formattedCpf }})</span
                >
              </nz-auto-option>
            </nz-autocomplete>
          </nz-form-control>
        </nz-form-item>
      </ng-container>
      <ng-container
        [formGroup]="documentForm"
        *ngIf="modelId && documentForm.value.title != undefined && documentType != typesOfDocuments[3]"
      >
        <nz-form-item>
          <nz-form-label nzFor="title">Título<span class="mandatory"> *</span></nz-form-label>
          <nz-form-control nzHasFeedback>
            <input nz-input formControlName="title" [attr.id]="'title'" />
            <nz-form-explain
              *ngIf="documentForm?.get('title').dirty && documentForm?.get('title').errors"
            >
              <ng-container *ngIf="documentForm?.get('title').hasError('required')">
                Campo obrigatório, por favor digite o título.
              </ng-container>
            </nz-form-explain>
          </nz-form-control>
        </nz-form-item>
      </ng-container>

      <app-infusion-protocol
        *ngIf="documentType === 'Infusão' && victaFeature"
        [infusion]="document"
        [protocolSelected]="selectedDocumentModel"
        (formEmitter)="documentForm = $event"
      ></app-infusion-protocol>

      <app-prescription
        *ngIf="documentType === typesOfDocuments[0] && !victaFeature && adding"
        [manipulatedProfile]="manipulatedProfile"
        [prescription]="document"
        [prescriptionModel]="selectedDocumentModel"
        [editMode]="!!modelId"
        [patient]="patient"
        (formEmitter)="documentForm = $event"
      ></app-prescription>

      <app-exam
        *ngIf="documentType === typesOfDocuments[1] && adding"
        [exam]="document"
        [examModel]="selectedDocumentModel"
        (formEmitter)="documentForm = $event"
      ></app-exam>

      <app-attestation
        *ngIf="documentType === typesOfDocuments[2] && adding"
        [patient]="patient"
        [attestationModel]="selectedDocumentModel"
        [attestation]="document"
        (formEmitter)="documentForm = $event"
      ></app-attestation>

      <app-orientation
        *ngIf="documentType === typesOfDocuments[3] && adding"
        [orientation]="document"
        [orientationModel]="selectedDocumentModel"
        [editMode]="!!modelId"
        [patient]="patient"
        (formEmitter)="documentForm = $event"
      ></app-orientation>

      <div class="add-prescription" *ngIf="!signed && !modelId">
        <button
          (click)="showVictaProtocols()"
          *ngIf="victaFeature && documentType === 'Infusão'"
          class="btn btn-icon btn-add-outiline text-capitalize"
          type="button"
        >
          <span class="btn-inner--text">Sugestão de Protocolos</span>
          <span class="btn-inner--icon"><i class="fa fa-plus"></i></span>
        </button>
        <button
          *ngIf="documentType !== 'Infusão'"
          (click)="showModelListFavoriteDocument()"
          class="btn btn-icon btn-add-outiline text-capitalize"
          type="button"
          [disabled]="!prescriber"
        >
          <span class="btn-inner--text">Selecionar modelo de {{ documentType }}</span>
          <span class="btn-inner--icon"><i class="fa fa-plus"></i></span>
        </button>
        <button
          (click)="showModalAddFavoriteDocument()"
          *ngIf="adding"
          class="btn btn-icon btn-add-outiline text-capitalize"
          type="button"
        >
          <span class="btn-inner--text">Salvar em favoritos</span>
          <span class="btn-inner--icon"><i class="fa fa-plus"></i></span>
        </button>
        <button
          (click)="toggleAdding()"
          *ngIf="!adding && documentType !== 'Infusão'"
          class="btn btn-icon btn-add-outiline text-capitalize"
          type="button"
        >
          <span class="btn-inner--text" *ngIf="!adding || documentType === 'Prescrição'"
            >Adicionar {{ documentType }}</span
          >
          <span class="btn-inner--icon"><i class="fa fa-plus"></i></span>
        </button>
      </div>
      <div class="add-prescription" *ngIf="modelId">
        <button
          (click)="updateDocumentModel(documentForm.value, modelId)"
          class="btn btn-icon btn-add-outiline text-capitalize"
          type="button"
        >
          <span class="btn-inner--text">Salvar modelo</span>
          <span class="btn-inner--icon"><i class="fa fa-check"></i></span>
        </button>
        <button
          (click)="cancelEditModel()"
          class="btn btn-icon btn-add-outiline text-capitalize"
          type="button"
        >
          <span class="btn-inner--text">Cancelar</span>
          <span class="btn-inner--icon"><i class="fa fa-times"></i></span>
        </button>
      </div>
    </form>
  </section>
</div>

<div class="footer d-flex align-items-center fixed-bottom justify-content-between">
  <div class="col">
    <div class=" d-flex align-self-center flex-column" *ngIf="!isMobile">
      <div *ngIf="isThemeActiveReceitaDigital()">
        <div class="d-flex flex-fill justify-content-end">
          <a class="mr-3 helpContainer" href="https://ajuda.receitadigital.com"><i class="fa fa-comments mr-1"></i>Fale Conosco.</a>
        </div>
      </div>
      <!-- <div>
        <span class="m-2">F: {{ frontendVersion }}</span>
        <span class="m-2">B: {{ backendVersion$ | async }}</span>
      </div> -->
    </div>
  </div>
  <button
    class="btn"
    type="button"
    (click)="saveAndPreviewPdf()"
    [ngClass]="{ disabled: invalidForm }"
    [disabled]="invalidForm"
  >
    Salvar e visualizar impressão <i class="fa fa-arrow-right"></i>
  </button>
  <button
    class="btn disabled"
    type="button"
    (click)="saveAndSignPdf()"
    [ngClass]="{ disabled: invalidForm }"
    [disabled]="invalidForm"
  >
    Salvar e assinar <i class="fa fa-arrow-right"></i>
  </button>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<app-modal-send-document></app-modal-send-document>
<app-modal-select-layout></app-modal-select-layout>
