import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-form-filter',
  templateUrl: './form-filter.component.html',
  styleUrls: ['./form-filter.component.scss']
})
export class FormFilterComponent implements OnInit {
  @Input() userType: string;
  @Input() tab: string;
  @Input() dependents = [];
  @Output() search = new EventEmitter();
  @Input() isVet = false;

  title: string;
  loadingFilter: boolean;
  filters = {
    patient: { createdAt: '', code: '', healthProfessional: '', archived: false, dependent: null },
    healthProfessional: { createdAt: '', code: '', patient: '', archived: false },
    pharmacy: { createdAt: '', dispensationCode: '', cpfBuyer: '', prescriberRegisterNumber: '' }
  };

  titles: any = {
    patient: 'Minhas prescrições',
    healthProfessional: 'Histórico de Documentos',
    pharmacy: 'Histórico de Retenções'
  };

  constructor() {}

  ngOnInit() {}

  get filter() {
    return this.filters[this.userType];
  }

  submit() {
    this.search.emit(this.filter);
  }
}
