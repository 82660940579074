<fieldset class="w-100 mb-2" [formGroup]="form" theme>
  <legend>Paciente Menor de Idade</legend>

  <nz-alert
    class="mb-4"
    nzType="warning"
    nzMessage="Ao preencher estes dados, você será capaz de emitir Receitas Simples, Receitas de Antimicrobianos, Atestados, Pedidos de Exames, Laudos, Relatórios, Orientações e Recibos."
  ></nz-alert>

  <div class="row">
    <div class="col-lg-2 col-md-2 col-sm-12" *ngIf="!isResponsible">
      <nz-form-item>
        <nz-form-label nzFor="cpf">CPF <span class="mandatory"> *</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            nz-input
            id="cpf"
            formControlName="cpf"
            placeholder="000.000.000-00"
            mask="000.000.000-00"
            autocomplete="disabled"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div [ngClass]="!isResponsible ? 'col-lg-5 col-md-5 col-sm-12' : 'col-lg-6 col-md-6 col-sm-12'">
      <nz-form-item>
        <nz-form-label nzFor="nome">Nome completo<span class="mandatory"> *</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            nz-input
            placeholder="ex: João Gilberto"
            formControlName="fullname"
            id="nome"
            maxlength="128"
            autocomplete="disabled"
          />
          <nz-form-explain *ngIf="form.get('fullname').dirty && form.get('fullname').errors">
            <ng-container *ngIf="form.get('fullname').hasError('required')">
              Campo obrigatório, por favor digite o nome e sobrenome.
            </ng-container>
            <ng-container
              *ngIf="!form.get('fullname').hasError('required') && form.get('fullname').hasError('nome')"
            >
              Por favor digite o nome e sobrenome.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div [ngClass]="!isResponsible ? 'col-lg-5 col-md-5 col-sm-12' : 'col-lg-6 col-md-6 col-sm-12'">
      <nz-form-item>
        <nz-form-label nzFor="name">Nome de exibição/social</nz-form-label>
        <nz-form-control>
          <input nz-input formControlName="name" id="name" maxlength="128" autocomplete="disabled" />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="dateOfBirth"
          >Data de nascimento<span class="mandatory"> *</span></nz-form-label
        >
        <nz-form-control nzHasFeedback>
          <input
            nz-input
            type="date"
            formControlName="dateOfBirth"
            placeholder="DD/MM/AAAA"
            id="dateOfBirth"
            autocomplete="disabled"
          />

          <nz-form-explain *ngIf="form.get('dateOfBirth').dirty && form.get('dateOfBirth').errors">
            <ng-container *ngIf="form.get('dateOfBirth').hasError('required')">
              Campo obrigatório, por favor digite data de nascimento.
            </ng-container>
            <ng-container
              *ngIf="
                !form.get('dateOfBirth').hasError('required') &&
                form.get('dateOfBirth').hasError('dateOfBirth')
              "
            >
              Nome inválido.
            </ng-container>
            <ng-container
              *ngIf="
                !form.get('dateOfBirth').hasError('required') &&
                form.get('dateOfBirth').hasError('isNotMinor')
              "
            >
              Dependente deve ser menor de idade.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-12">
      <nz-form-item>
        <nz-form-label nzFor="sex">Sexo<span class="mandatory"> *</span></nz-form-label>
        <nz-form-control nzHasFeedback>
          <select formControlName="sex" id="sex" nz-input nzPlaceHolder="Selecione">
            <option value="M">Masculino</option>
            <option value="F">Feminino</option>
          </select>
          <nz-form-explain *ngIf="form.get('sex').dirty && form.get('sex').errors">
            <ng-container *ngIf="form.get('sex').hasError('required')">
              Campo obrigatório, por favor selecione o sexo.
            </ng-container>
          </nz-form-explain>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="form.get('membership') as membership">
      <nz-form-item [formGroup]="membership">
        <nz-form-label nzFor="mothersName">Nome da mãe</nz-form-label>

        <nz-form-control nzHasFeedback>
          <input
            nz-input
            placeholder="Digite o nome da mãe"
            formControlName="mothersName"
            id="mothersName"
            maxlength="128"
            autocomplete="disabled"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div class="row mb-3 mt-3 align-items-center" *ngIf="!isResponsible && consulted">
    <div class="col-12">
      <button class="ok-btn btn-cancel" nz-button (click)="changedNewRepnsible()">
        Alterar Responsável
      </button>
    </div>
  </div>

  <div class="row mb-3" *ngIf="!isResponsible && !consulted">
    <div class="col 12">
      <nz-divider nzOrientation="horizontal"></nz-divider>
    </div>
    <div class="col-12" [formGroup]="form.get('responsible')">
      <nz-form-item>
        <nz-form-label nzFor="cpfResponsible"
          >CPF do responsável <span class="mandatory">*</span></nz-form-label
        >
        <div class="row align-items-center">
          <div class="col-5">
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                id="cpfResponsible"
                placeholder="Digite o CPF"
                mask="000.000.000-00"
                formControlName="cpf"
                autocomplete="disabled"
              />
            </nz-form-control>
          </div>
          <button
            nz-button
            class="btn-primary"
            (click)="searchCpf()"
            [disabled]="loading || cpf.invalid || (cpf.errors && cpf.hasError('cpf'))"
          >
            <span *ngIf="!loading">OK</span>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              *ngIf="loading"
            ></span>
          </button>
        </div>
      </nz-form-item>
    </div>
  </div>
</fieldset>
