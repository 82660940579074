import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { DocumentService } from '@app/modules/document/services/document.service';
import { PrescriptionService } from '@app/modules/document/services/prescription.service';
import { RetentionService } from '@app/modules/document/services/retention.service';
import { ModalCancelRetentionComponent } from '@app/modules/historic/modal-cancel-retention/modal-cancel-retention.component';
import { PatientsService } from '@app/modules/patients/services/patients.service';
import { PharmacyService } from '@app/modules/pharmacy/services/pharmacy.service';
import { ModalSendDocumentComponent } from '@app/shared/components/modal-send-document/modal-send-document.component';
import { HealthProfessional, Patient } from '@app/shared/models';
import { Attestation } from '@app/shared/models/attestation';
import { User } from '@app/shared/models/decodedLoginToken';
import { Exam } from '@app/shared/models/exam';
import { Orientation } from '@app/shared/models/orientation';
import { Prescription } from '@app/shared/models/prescription';
import { StateService } from '@app/shared/services/state.service';
import { NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { AttestationService } from '../document/pages/attestation/attestation.service';
import { OrientationService } from '../document/pages/orientation/orientation.service';
import { ExamService } from '../document/services/exam.service';
import { HealthProfessionalService } from '../health-professional/services/health-professional.service';

@Component({
  selector: 'app-historic',
  templateUrl: './historic.component.html',
  styleUrls: ['./historic.component.scss']
})
export class HistoricComponent implements OnInit, OnDestroy {
  navTitle: string;
  retentions: any[] = [];
  prescriptions: any[] = [];
  attestations: Attestation[] = [];
  exams: Exam[] = [];
  orientations: Orientation[] = [];
  retentionOfThirdParties: any[] = [];
  pharmacyId: string;
  loading: boolean;
  loadingFilter: boolean;
  patient: Patient;
  userType = '';
  patientId = '';
  selectedTabIndex = 0;
  filter: any = {};
  pageSize = 7;
  selectedTab = 'prescription';
  tabs = [];
  paginationData = [
    { skip: 0, totalRecord: 0, orderBy: 'updatedAt', sortOrder: -1 },
    { skip: 0, totalRecord: 0, orderBy: 'updatedAt', sortOrder: -1 },
    { skip: 0, totalRecord: 0, orderBy: 'updatedAt', sortOrder: -1 },
    { skip: 0, totalRecord: 0, orderBy: 'updatedAt', sortOrder: -1 }
  ];
  healthProfessional: HealthProfessional;
  scrollConfig = { x: '1010px' };
  isVisibleModalCancelRetention = false;

  @ViewChild(ModalSendDocumentComponent, { static: true })
  modalSendDocumentComponent: ModalSendDocumentComponent;

  constructor(
    private authService: AuthService,
    private patientService: PatientsService,
    private pharmacyService: PharmacyService,
    private documentService: DocumentService,
    private prescriptionService: PrescriptionService,
    private attestationService: AttestationService,
    private examsService: ExamService,
    private orientationService: OrientationService,
    private retentionService: RetentionService,
    private healthProfessionalService: HealthProfessionalService,
    private modalService: NzModalService,
    private notification: NzNotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private stateService: StateService
  ) {}

  async ngOnInit() {
    this.userType = this.user.userType;
    this.navTitle = this.user.userType === 'pharmacy' ? 'Retenções' : 'Histórico de Documentos';
    this.tabs =
      this.user.userType === 'pharmacy'
        ? ['prescription', 'retentionOfThirdParties']
        : ['prescription', 'attestation', 'exam', 'orientation'];

    if (this.user.userType === 'patient') {
      this.loading = true;
      this.patient = await this.patientService.getPatientByUserId(this.user._id).toPromise();
    } else if (this.user.userType === 'healthProfessional') {
      this.loading = true;
      this.healthProfessional = await this.healthProfessionalService
        .getHealthProfessional(this.user._id)
        .toPromise();
    }

    const { patientId } = this.route.snapshot.queryParams;
    if (patientId) {
      this.patientId = patientId;
    }

    await this.initData();
  }

  async initData() {
    try {
      this.loading = true;
      const filter = this.getBaseFilter();
      await this.loadPrescriptions(filter);
      this.loading = false;
      await this.loadAttestations(filter);
      await this.loadExams(filter);
      await this.loadOrientations(filter);
      await this.loadRetentionOfThirdParties(filter);
    } catch (error) {
      console.error(error);
      this.loading = false;
    }
  }

  get user(): User {
    return this.authService.user();
  }

  async loadData() {
    try {
      this.loadingFilter = true;
      const filter = this.getBaseFilter();
      switch (this.selectedTab) {
        case 'prescription':
          await this.loadPrescriptions(filter);
          break;
        case 'retentionOfThirdParties':
          await this.loadRetentionOfThirdParties(filter);
          break;
        case 'attestation':
          await this.loadAttestations(filter);
          break;
        case 'orientation':
          await this.loadOrientations(filter);
          break;
        default:
          await this.loadExams(filter);
      }
      this.loadingFilter = false;
    } catch (error) {
      this.loadingFilter = false;
    }
  }

  async loadPrescriptions(filter) {
    let result = null;
    switch (this.user.userType) {
      case 'healthProfessional':
        result = await this.prescriptionService
          .getPrescriptionsByPrescriberUserId(this.user._id, filter)
          .toPromise();
        break;

      case 'pharmacy':
        result = await this.pharmacyService
          .getRetentionHistoryByFilter(this.user._id, filter)
          .toPromise();
        break;
      default:
        result = await this.prescriptionService
          .getPrescriptionsByPatientId(filter.dependent || this.patient._id, filter)
          .toPromise();
    }
    if (result) {
      this.prescriptions = result.prescriptions;
      this.paginationData[0].totalRecord = result.totalRecord;
    }
  }

  async loadAttestations(filter) {
    let result = null;
    switch (this.user.userType) {
      case 'healthProfessional':
        result = await this.attestationService
          .getAttestationsByPrescriberUserId(this.user._id, filter)
          .toPromise();
        break;
      case 'patient':
        result = await this.attestationService
          .getAttestationsByPatientId(this.patient._id, filter)
          .toPromise();
        break;
      default:
        this.attestations = [];
    }
    if (result) {
      this.attestations = result.attestations;
      this.paginationData[1].totalRecord = result.totalRecord;
    }
  }

  async loadExams(filter) {
    let result = null;
    switch (this.user.userType) {
      case 'healthProfessional':
        result = await this.examsService.getExamsByPrescriberUserId(this.user._id, filter).toPromise();
        break;
      case 'patient':
        result = await this.examsService.getExamsByPatientId(this.patient._id, filter).toPromise();
        break;
      default:
        this.exams = [];
    }
    if (result) {
      this.exams = result.exams;
      this.paginationData[2].totalRecord = result.totalRecord;
    }
  }

  async loadOrientations(filter) {
    let result = null;
    switch (this.user.userType) {
      case 'healthProfessional':
        result = await this.orientationService
          .getOrientationsByPrescriberUserId(this.user._id, filter)
          .toPromise();
        break;
      case 'patient':
        result = await this.orientationService
          .getOrientationsByPatientId(this.patient._id, filter)
          .toPromise();
        break;
      default:
        this.orientations = [];
    }
    if (result) {
      this.orientations = result.orientations;
      this.paginationData[3].totalRecord = result.totalRecord;
    }
  }

  async loadRetentionOfThirdParties(filter) {
    if (this.user.userType === 'pharmacy') {
      const result = await this.retentionService
        .getRetentionOfThirdPartiesHistoryByFilter(this.user._id, filter)
        .toPromise();
      this.retentionOfThirdParties = result.retentionOfThirdParties;
      this.paginationData[1].totalRecord = result.totalRecord;
    }
  }

  ngOnDestroy(): void {
    window.sessionStorage.clear();
  }

  getBaseFilter() {
    return {
      ...this.filter,
      patientId: this.patientId,
      limit: this.pageSize,
      skip: this.paginationData[this.selectedTabIndex].skip,
      orderBy: this.paginationData[this.selectedTabIndex].orderBy,
      sortOrder: this.paginationData[this.selectedTabIndex].sortOrder,
      professionalType: this.healthProfessional && this.healthProfessional.professionalType
    };
  }

  async search(filter) {
    delete this.patientId;
    this.filter = filter;
    this.paginationData[this.selectedTabIndex].skip = 0;
    this.loadData();
  }

  async loadPrescriptionByPharmacy() {
    this.prescriptions = await this.pharmacyService.getRetentionHistory(this.user._id).toPromise();
  }

  async pageIndexChanged(pageIndex: any) {
    this.paginationData[this.selectedTabIndex].skip = this.pageSize * (pageIndex - 1);
    await this.loadData();
  }

  tabChange(args: any): void {
    this.selectedTabIndex = args.index;
    this.selectedTab = this.tabs[this.selectedTabIndex];
  }

  getPreWriterInfo(prescription) {
    const prescriber = {
      name: '',
      label: '',
      regionalCouncil: { label: '', number: 0, uf: '' },
      specialties: ''
    };

    if (prescription && prescription.healthProfessional) {
      const { professionalType, name, regionalCouncilNumber } = prescription.healthProfessional;

      prescriber.label = HealthProfessional.getProfessionalTypeName(professionalType);
      prescriber.name = name;
      prescriber.regionalCouncil.label = HealthProfessional.getRegionalCouncilLabel(professionalType);
      prescriber.regionalCouncil.number = regionalCouncilNumber.number;
      prescriber.regionalCouncil.uf = regionalCouncilNumber.uf;
    }
    return prescriber;
  }

  async downloadPrescriptionPdf(prescription) {
    try {
      this.loading = true;
      if (prescription.status === 'Não Assinada') {
        await this.prescriptionService.generatePdf(prescription.code);
      } else {
        await this.prescriptionService.downloadOrGeneratePdf(prescription.code);
      }
      this.notification.success('Sucesso', 'Download realizado com sucesso!');
    } catch (err) {
      console.error(err);
    }
    this.loading = false;
  }

  async downloadPrescriptionPdfOfThirdParties(retention) {
    try {
      this.loading = true;
      await this.retentionService.downloadThirdPartyPdf(retention._id);
    } catch (err) {
      console.error(err);
      if (err.status === 404) {
        this.notification.warning('Aviso', 'PDF não encontrado');
      }
    }
    this.loading = false;
  }

  async showModalCancelPrescrition(prescription) {
    this.modalService.confirm({
      nzTitle: 'Alerta',
      nzContent:
        `Tem certeza que deseja excluir <b>permanentemente</b> o documento ${prescription.code}? ` +
        'Esta ação impedirá que o paciente acesse o documento na plataforma e a farmácia ' +
        'não poderá validá-lo no processo de venda do medicamento.',
      nzOnOk: () => {
        this.prescriptionService.delete(prescription._id).subscribe(
          _ => {
            this.notification.success('Sucesso', 'Prescrição excluída com sucesso');
            this.loadPrescriptions(this.getBaseFilter());
          },
          err => {
            this.notification.warning('Aviso', err.error.message);
          }
        );
      }
    });
    return false;
  }

  async showModalCancelExam(exam) {
    this.modalService.confirm({
      nzTitle: 'Alerta',
      nzContent:
        `Tem certeza que deseja excluir <b>permanentemente</b> o documento ${exam.code}? ` +
        'Esta ação impedirá que o paciente acesse o documento na plataforma e o ' +
        '<b>Laboratório/Clínica</b> não poderá validá-lo na prestação do serviço.',
      nzOnOk: () => {
        this.examsService.delete(exam._id).subscribe(
          _ => {
            this.notification.success('Sucesso', 'Exame excluído com sucesso');
            this.loadExams(this.getBaseFilter());
          },
          err => {
            this.notification.warning('Aviso', err.error.message);
          }
        );
      }
    });
    return false;
  }

  async showModalCancelAttestation(exam) {
    this.modalService.confirm({
      nzTitle: 'Alerta',
      nzContent:
        `Tem certeza que deseja excluir <b>permanentemente</b> o documento ${exam.code}? ` +
        'Esta ação impedirá que o paciente acesse o documento na plataforma.',
      nzOnOk: () => {
        this.attestationService.delete(exam._id).subscribe(
          _ => {
            this.notification.success('Sucesso', 'Exame excluído com sucesso');
            this.loadAttestations(this.getBaseFilter());
          },
          err => {
            this.notification.warning('Aviso', err.error.message);
          }
        );
      }
    });
    return false;
  }

  async showModalCancelOrientation(orientation) {
    this.modalService.confirm({
      nzTitle: 'Alerta',
      nzContent:
        `Tem certeza que deseja excluir <b>permanentemente</b> o documento ${orientation.code}? ` +
        'Esta ação impedirá que o paciente acesse o documento na plataforma e a ' +
        '<b>Instituição/Empresa</b> não poderá reconhecê-lo como válido.',
      nzOnOk: () => {
        this.orientationService.delete(orientation._id).subscribe(
          _ => {
            this.notification.success('Sucesso', 'Documento excluído com sucesso');
            this.loadOrientations(this.getBaseFilter());
          },
          err => {
            this.notification.warning('Aviso', err.error.message);
          }
        );
      }
    });
    return false;
  }

  showModalCancelRetention(retention) {
    const modal = this.modalService.create({
      nzContent: ModalCancelRetentionComponent,
      nzComponentParams: {
        userId: this.user._id,
        retentionId: retention._id
      },
      nzFooter: null
    });
    modal.afterOpen.subscribe(() => {
      modal
        .getContentComponent()
        .updateListPrescriptionEvent.subscribe(data => this.search(this.filter));
    });
  }

  showModalSendDocument(item: Prescription) {
    const title = 'Documento Assinado';
    this.modalSendDocumentComponent.createTplModal(item.code, item.patient._id || item.pet._id, {
      title,
      resend: true,
      isPet: !!item.pet
    });
  }

  archiveItem(code: string) {
    this.modalService.confirm({
      nzTitle: 'Alerta',
      nzContent: `Tem certeza que deseja arquivar o documento ${code}? Você poderá acessá-lo novamente através do filtro "Arquivados".`,
      nzOnOk: () =>
        this.documentService.archive(code).subscribe(
          res => this.initData(),
          err => this.notification.error('Aviso', 'Erro ao arquivar documento')
        )
    });
  }

  unarchiveItem(code: string) {
    this.modalService.confirm({
      nzTitle: 'Alerta',
      nzContent: `Tem certeza que deseja <b>desarquivar</b> o documento ${code}?`,
      nzOnOk: () =>
        this.documentService.unarchive(code).subscribe(
          res => this.initData(),
          err => this.notification.error('Aviso', 'Erro ao desarquivar documento')
        )
    });
  }

  navigateToNew({ code, version }, document: string) {
    const regex = /^[a-z]/i;
    const queryParams = regex.test(code[0]) ? {} : { queryParams: { document } };
    const path = version === 'BETA' ? '/document' : '/prescription';
    this.router.navigate([path, code, 'new'], queryParams);
  }

  navigateToEdit({ code, version }, documentType: string) {
    const regex = /^[a-z]/i;
    const queryParams = regex.test(code[0]) ? {} : { queryParams: { documentType } };
    const rennovaFeature = this.user.features && this.user.features.find(f => f.name === 'Rennova');
    this.stateService.data = { isRennova: rennovaFeature && code.substring(0, 3) === 'REN' };
    const path = version === 'BETA' ? '/document' : '/prescription';
    this.router.navigate([path, code, 'edit'], queryParams);
  }

  async onSortChange(params) {
    this.paginationData[this.selectedTabIndex].orderBy = params.key;
    this.paginationData[this.selectedTabIndex].sortOrder = params.value === 'descend' ? -1 : 1;
    await this.loadData();
  }

  hasSignedItem(items: any[]): boolean {
    return items.some(i => i.status === 'Assinada');
  }

  getMaleStatus(status: string) {
    switch (status) {
      case 'Assinada':
        return 'Assinado';
      case 'Não Assinada':
        return 'Não Assinado';
      case 'Dispensada Parcial':
        return 'Dispensado Parcial';
      case 'Dispensada Total':
        return 'Dispensado Total';
      case 'Excluída':
        return 'Excluído';
    }
  }
}
