<div class="modal-header" theme>
  <h4 class="modal-title pull-left text-white">
    Selecionar Pet
  </h4>

  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ul *ngIf="pets.length > 0" class="list-unstyled">
    <li *ngFor="let pet of pets" class="list-item-icon">
      {{ pet.name | titlecase }}
      <button
        *ngIf="!hasDependent(pet)"
        (click)="addPetPatient(pet)"
        title="Vincular pet"
        class="btn-icon mr-0 p-0"
      >
        <i class="fa fa-plus"></i>
      </button>

      <button *ngIf="hasDependent(pet)" title="Pet vinculado" class="btn-icon mr-0 p-0">
        <i class="fa-solid fa-check"></i>
      </button>
    </li>
  </ul>

  <p *ngIf="pets.length == 0">O Responsável/Tutor ainda não possui nenhum pet cadastrado</p>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" (click)="showModalAddPatient()">
    adicionar novo pet
  </button>
</div>
