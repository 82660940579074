<div class="modal-body" theme>
  <div *ngIf="!resend">
    <p style="font-weight: bold; text-align: justify;" *ngIf="isSuccessMsg && !rennovaFeature">
      O link da Receita Digital foi enviado com sucesso! Se precisar, este documento poderá ser acessado
      pelo seu Histórico de Documentos ou diretamente no perfil do paciente em
      <a href="https://receitadigital.com/">receitadigital.com</a>
    </p>

    <p style="font-weight: bold;text-align: justify;" *ngIf="isSuccessMsg && rennovaFeature">
      O link da Receita Digital foi enviado com sucesso! Se precisar, este documento poderá ser acessado
      pelo seu Histórico de Documentos.
    </p>

    <p style="font-weight: bold;text-align: justify;" *ngIf="!isSuccessMsg && !rennovaFeature">
      Você optou por não enviar o link de acesso de forma automática. Mas fique tranquilo, este documento
      poderá ser acessado pelo seu Histórico de Documentos e diretamente no perfil do paciente em
      <a href="https://receitadigital.com/">receitadigital.com</a>
    </p>

    <p style="font-weight: bold;text-align: justify;" *ngIf="!isSuccessMsg && rennovaFeature">
      Você optou por não enviar o link de acesso de forma automática. Mas fique tranquilo, este documento
      poderá ser acessado pelo seu Histórico de Documentos.
    </p>

    <form [formGroup]="form">
      <label nz-checkbox formControlName="doNotShowAgainModalSuccessPrescription"
        >Não mostrar novamente</label
      >
    </form>
  </div>

  <div *ngIf="resend">
    <h4 class="title" [ngStyle]="{ color: colorSetting?.primary }">
      {{ title }}
    </h4>
    <p>Código do documento: {{ code }}</p>
  </div>
</div>

<div
  class="modal-footer"
  [ngStyle]="{
    'background-color': colorSetting?.secondary,
    color: colorSetting?.secondary
  }"
  theme
>
  <button
    (click)="close()"
    class="btn"
    theme
    [ngStyle]="
      colorSetting && {
        'background-color': colorSetting?.primary,
        'border-color': colorSetting?.primary
      }
    "
  >
    Ok
  </button>
</div>
