<div theme>
  <header>
    <span class="title">
      DADOS PESSOAIS
    </span>
  </header>
  <div class="modal-body">
    <form nz-form [formGroup]="form">
      <div class="row" *ngIf="user?.userType !== 'pharmacy'">
        <nz-form-item class="col-md">
          <nz-form-label nzFor="name"
            >Nome de exibição/social <span *ngIf="!isAdmin" class="mandatory">*</span></nz-form-label
          >
          <nz-form-control>
            <nz-input-group>
              <input
                id="name"
                type="text"
                nz-input
                formControlName="name"
                maxlength="128"
                autocomplete="disabled"
              />
            </nz-input-group>
            <nz-form-explain *ngIf="form.get('name').dirty && form.get('name').errors">
              <ng-container *ngIf="form.get('name').hasError('required')">
                Campo obrigatório, por favor digite o seu nome e sobrenome.
              </ng-container>
            </nz-form-explain>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item class="col-md-6" *ngIf="user.userType === 'healthProfessional'">
          <nz-form-label nzFor="sex"
            >Sexo <span *ngIf="!isAdmin" class="mandatory">*</span></nz-form-label
          >
          <nz-form-control nzHasFeedback>
            <select nz-input id="sex" formControlName="sex" nzPlaceHolder="Selecione">
              <option value="M">Masculino</option>
              <option value="F">Feminino</option>
            </select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="row">
        <div class="col-6">
          <nz-form-item *ngIf="form.get('cellphone') as cellphone">
            <nz-form-label nzFor="cellphone"
              >Telefone 1 <span *ngIf="!isAdmin" class="mandatory">*</span>
              <app-popover-button
                *ngIf="user.userType === 'healthProfessional' || user.userType === 'patient'"
                [content]="popoverContent"
              ></app-popover-button>
            </nz-form-label>
            <nz-form-control>
              <nz-input-group>
                <input
                  id="cellphone"
                  type="text"
                  nz-input
                  formControlName="cellphone"
                  placeholder="(21) 92222-2222"
                  [mask]="cellphone.value?.length <= 10 ? '(00) 0000-00009' : '(00) 00000-0000'"
                  mask="(00) 0000-0000"
                  autocomplete="disabled"
                />
              </nz-input-group>
              <nz-form-explain *ngIf="cellphone.dirty && cellphone.errors">
                <ng-container *ngIf="cellphone.hasError('required')">
                  Campo obrigatório, por favor digite seu telefone para contato.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col-6">
          <nz-form-item *ngIf="form.get('telephone') as telephone">
            <nz-form-label
              nzFor="telephone"
              [ngClass]="
                user.userType === 'healthProfessional' || user.userType === 'patient' ? 'cellphone' : ''
              "
              >Telefone 2
            </nz-form-label>

            <nz-form-control>
              <nz-input-group>
                <input
                  id="telephone"
                  type="text"
                  nz-input
                  formControlName="telephone"
                  placeholder="(21) 2222-2222"
                  [mask]="telephone.value?.length <= 10 ? '(00) 0000-00009' : '(00) 00000-0000'"
                  autocomplete="disabled"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-12" *ngIf="user.userType === 'healthProfessional' && isVeterinarian">
          <nz-form-item nzFor="mapaSipeagro">
            <nz-form-label>MAPA Sipeagro </nz-form-label>

            <nz-form-control>
              <nz-input-group>
                <input
                  id="mapaSipeagro"
                  type="text"
                  nz-input
                  formControlName="mapaSipeagro"
                  placeholder="XXXXX/YYYY"
                  autocomplete="disabled"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div
        class="row"
        *ngIf="user.userType === 'healthProfessional' && form.get('regionalCouncilNumber') as rcn"
        [formGroup]="form.get('regionalCouncilNumber')"
      >
        <div class="col-6">
          <nz-form-item>
            <nz-form-label nzFor="number"
              >{{ rcn.value.label }}<span *ngIf="!isAdmin" class="mandatory"> *</span>
            </nz-form-label>
            <nz-form-control nzHasFeedback>
              <nz-input-group>
                <input
                  id="number"
                  nz-input
                  type="text"
                  formControlName="number"
                  autocomplete="disabled"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  maxlength="10"
                  digitOnly
                />
              </nz-input-group>
              <nz-form-explain *ngIf="rcn.get('number').dirty && rcn.get('number').errors">
                <ng-container *ngIf="rcn.get('number').hasError('required')">
                  Campo obrigatório, por favor digite seu {{ rcn.value.label }}.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-6">
          <nz-form-item>
            <nz-form-label nzFor="uf"
              >UF<span *ngIf="!isAdmin" class="mandatory"> *</span></nz-form-label
            >
            <nz-form-control nzHasFeedback>
              <nz-select
                formControlName="uf"
                id="uf"
                nzShowSearch
                nzAllowClear
                nzDropdownClassName="account-select"
                nzPlaceHolder="Selecione"
              >
                <nz-option
                  *ngFor="let state of states"
                  [nzLabel]="state?.sigla"
                  [nzValue]="state?.sigla"
                >
                </nz-option>
              </nz-select>
              <nz-form-explain
                *ngIf="
                  form.get('regionalCouncilNumber.uf').dirty &&
                  form.get('regionalCouncilNumber.uf').errors
                "
              >
                <ng-container *ngIf="form.get('regionalCouncilNumber.uf').hasError('required')">
                  Campo obrigatório, por favor selecione o UF do {{ rcn.value.label }}.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-12" *ngIf="isDentist; else doctorSpecialties">
          <nz-form-item>
            <nz-form-label nzFor="specialties"
              >Especialidades <span style="font-size: 10px;">no máximo 3 opções</span
              ><span *ngIf="!isAdmin" class="mandatory"> *</span></nz-form-label
            >
            <nz-form-control>
              <nz-select
                id="specialties"
                nzSize="large"
                nzMode="multiple"
                nzDropdownClassName="account-select"
                nzPlaceHolder="Selecione a sua especialidade"
                [(ngModel)]="selectedSpecialties"
                (ngModelChange)="addSpecialties()"
                [ngModelOptions]="{ standalone: true }"
                nzAllowClear
                nzShowSearch
              >
                <nz-option
                  *ngFor="let specialty of specialtiesOptions; trackBy: trackById"
                  [nzLabel]="specialty?.description"
                  [nzValue]="specialty?._id"
                >
                </nz-option>
              </nz-select>
              <nz-form-explain *ngIf="specialtiesForm.dirty && specialtiesForm.errors">
                <ng-container *ngIf="specialtiesForm.hasError('minLengthArray')">
                  Campo obrigatório, por favor selecione uma especialidade.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>

        <ng-template #doctorSpecialties>
          <div
            class="col-12"
            *ngFor="let specialty of specialtiesForm.controls; let i = index"
            [@slideInOut]
            [formGroup]="specialty"
          >
            <div class="row justify-content-between">
              <div [ngClass]="specialtiesForm.length > 1 ? 'col-11' : 'col-12'">
                <nz-divider nzOrientation="left"></nz-divider>
              </div>
              <div
                class="col-1 pl-0 d-flex align-items-center justify-content-center"
                *ngIf="specialtiesForm.length > 1"
              >
                <a
                  (click)="$event.preventDefault()"
                  [nzContent]="contentTemplateSpecialty"
                  class="popover-trigger"
                  nz-popover
                  nzPlacement="right"
                >
                  <i class="fa fa-ellipsis-solid"></i>
                </a>
                <ng-template #contentTemplateSpecialty>
                  <a (click)="specialtiesForm.removeAt(i)"><i class="fa fa-trash-solid"></i></a>
                </ng-template>
              </div>
            </div>
            <div class="row">
              <div [ngClass]="isVeterinarian ? 'col-12' : 'col-7'">
                <nz-form-item>
                  <nz-form-label [nzFor]="'specialty_' + i"
                    >Especialidade <span *ngIf="specialtiesForm.length > 1">{{ i + 1 }}</span>
                    <span *ngIf="!isAdmin" class="mandatory"> *</span></nz-form-label
                  >
                  <nz-form-control nzHasFeedback *ngIf="specialty.get('specialty') as specialtyId">
                    <nz-select
                      [id]="'specialty_' + i"
                      formControlName="specialty"
                      nzSize="large"
                      nzDropdownClassName="account-select"
                      nzPlaceHolder="Selecione uma especialidade"
                      nzAllowClear
                      nzShowSearch
                    >
                      <nz-option
                        *ngFor="let specialty of specialtiesOptions; trackBy: trackById"
                        class="disableClick"
                        [nzLabel]="specialty?.title"
                        [nzValue]="specialty?._id"
                      >
                      </nz-option>
                    </nz-select>
                    <nz-form-explain *ngIf="specialtyId.dirty && specialtyId.errors">
                      <ng-container *ngIf="specialtyId.hasError('required')">
                        Campo obrigatório, por favor selecione uma especialidade.
                      </ng-container>
                    </nz-form-explain>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div class="col-5" *ngIf="!isVeterinarian">
                <nz-form-item *ngIf="specialty.get('rqe') as rqe">
                  <label
                    *ngIf="isMobile"
                    nz-checkbox
                    formControlName="notExists"
                    (nzCheckedChange)="checkRQE(rqe, $event)"
                    style="font-size: 14px;"
                    >Não possuo RQE</label
                  >
                  <div class="row" *ngIf="!isMobile">
                    <div class="col-5 pr-0">
                      <nz-form-label [nzFor]="'rqe_' + i"
                        >RQE <span *ngIf="!isAdmin" class="mandatory">*</span></nz-form-label
                      >
                    </div>
                    <div class="col pl-0">
                      <label
                        nz-checkbox
                        formControlName="notExists"
                        (nzCheckedChange)="checkRQE(rqe, $event)"
                        style="font-size: 14px;"
                        >Não possuo</label
                      >
                    </div>
                  </div>
                  <nz-form-control nzHasFeedback>
                    <input nz-input [id]="'rqe_' + i" formControlName="rqe" maxlength="20" />
                    <nz-form-explain *ngIf="rqe.dirty && rqe.errors">
                      <ng-container *ngIf="rqe.hasError('required')">
                        Campo obrigatório, por favor informe o RQE da especialidade.
                      </ng-container>
                    </nz-form-explain>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
          <div class="col-12">
            <button
              *ngIf="specialtiesForm.length < 3"
              (click)="addSpecialty()"
              class="btn btn-icon btn-success btn-add text-capitalize"
              type="button"
            >
              <span class="btn-inner--text">Adicionar Outra Especialidade</span>
              <span class="btn-inner--icon"><i class="fa fa-plus"></i></span>
            </button>
          </div>
        </ng-template>
      </div>

      <div
        class="row"
        *ngIf="
          (user.userType === 'patient' || user.userType === 'pharmacist') &&
          (userTypeLogged === 'patient' || userTypeLogged === 'pharmacy' || isAdmin)
        "
      >
        <nz-form-item
          [ngClass]="user.userType === 'patient' && userTypeLogged === 'patient' ? 'col-6' : 'col'"
        >
          <nz-form-label nzFor="identity"
            >Identidade <span *ngIf="!isAdmin" class="mandatory">*</span></nz-form-label
          >
          <nz-form-control nzErrorTip="Campo obrigatório, por favor digite a sua identidade">
            <nz-input-group>
              <input
                id="identity"
                type="text"
                nz-input
                formControlName="identity"
                placeholder="ex: 18.040.818-5"
                maxlength="12"
                autocomplete="disabled"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item [class.col-6]="user.userType === 'patient'" *ngIf="user.userType === 'patient'">
          <nz-form-label nzFor="sex"
            >Sexo<span *ngIf="!isAdmin" class="mandatory"> *</span></nz-form-label
          >
          <nz-form-control nzHasFeedback>
            <select formControlName="sex" id="sex" nz-input nzPlaceHolder="Selecione">
              <option value="M">Masculino</option>
              <option value="F">Feminino</option>
            </select>
          </nz-form-control>
        </nz-form-item>
      </div>

      <ng-container *ngIf="user.userType == 'pharmacy'">
        <nz-form-item>
          <nz-form-label nzFor="cnpj"
            >CNPJ <span *ngIf="!isAdmin" class="mandatory">*</span></nz-form-label
          >
          <nz-form-control nzErrorTip="Campo obrigatório, por favor digite o seu CNPJ">
            <nz-input-group>
              <input
                id="cnpj"
                type="text"
                nz-input
                formControlName="cnpj"
                placeholder="20.259.798/0001-06"
                mask="00.000.000/0000-00"
                autocomplete="disabled"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="nomeFantasia">Nome Fantasia</nz-form-label>

          <nz-input-group>
            <input
              [attr.id]="'nomeFantasia'"
              formControlName="fantasyName"
              nz-input
              formControlName="fantasyName"
              placeholder="ex: Farmácia Dona Santa"
              autocomplete="disabled"
            />
          </nz-input-group>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="email"
            >E-mail
            <span *ngIf="user.userType === userTypeLogged" class="mandatory">*</span></nz-form-label
          >
          <nz-form-control nzHasFeedback>
            <nz-input-group>
              <input
                id="email"
                type="text"
                nz-input
                formControlName="email"
                style="text-transform: lowercase;"
                (keydown.space)="$event.preventDefault()"
                autocomplete="disabled"
              />
            </nz-input-group>
            <nz-form-explain>
              <ng-container *ngIf="emailForm.hasError('exists'); else required">
                E-mail já existe, por favor escolha outro e-mail.
              </ng-container>
              <ng-template #required>
                <ng-container *ngIf="emailForm.hasError('required') && emailForm.dirty; else invalid">
                  <span class="error">Campo obrigatório, por favor digite seu e-mail.</span>
                </ng-container>
              </ng-template>
              <ng-template #invalid>
                <ng-container *ngIf="emailForm.invalid && emailForm.value.length > 0">
                  <span class="error">E-mail inválido, por favor digite um e-mail válido.</span>
                </ng-container>
              </ng-template>
            </nz-form-explain>
          </nz-form-control>
        </nz-form-item>
      </ng-container>

      <ng-container *ngIf="user.userType !== 'pharmacy'">
        <nz-divider nzOrientation="left"></nz-divider>

        <div class="row">
          <div class="col-md-6">
            <nz-form-item>
              <nz-form-label nzFor="email"
                >E-mail de login
                <span class="mandatory" *ngIf="user.userType === userTypeLogged">*</span></nz-form-label
              >
              <nz-form-control nzHasFeedback>
                <nz-input-group>
                  <input
                    id="email"
                    type="text"
                    nz-input
                    formControlName="email"
                    style="text-transform: lowercase;"
                    (keydown.space)="$event.preventDefault()"
                    autocomplete="disabled"
                  />
                </nz-input-group>
                <nz-form-explain *ngIf="emailForm.dirty && emailForm.errors">
                  <ng-container *ngIf="emailForm.hasError('exists')">
                    E-mail já existe, por favor escolha outro e-mail.
                  </ng-container>
                  <ng-container *ngIf="!emailForm.hasError('exists')">
                    E-mail inválido, por favor digite um e-mail válido.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-md-6">
            <nz-form-item *ngIf="form.get('emailContact') as emailContact">
              <nz-form-label nzFor="emailContact"
                >E-mail de contato
                <span class="mandatory" *ngIf="user.userType === userTypeLogged">*</span></nz-form-label
              >
              <nz-form-control nzHasFeedback>
                <nz-input-group>
                  <input
                    id="emailContact"
                    type="text"
                    nz-input
                    formControlName="emailContact"
                    style="text-transform: lowercase;"
                    (keydown.space)="$event.preventDefault()"
                    autocomplete="disabled"
                  />
                </nz-input-group>
                <nz-form-explain *ngIf="emailContact.dirty && emailContact.errors">
                  <ng-container *ngIf="emailContact.errors">
                    E-mail inválido, por favor digite um e-mail válido.
                  </ng-container>
                </nz-form-explain>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="row">
          <nz-form-item
            *ngIf="userTypeLogged !== 'pharmacy'"
            [ngClass]="user.userType === 'patient' ? 'col-md-6' : 'col-md'"
          >
            <nz-form-label nzFor="cpf"
              >CPF <span *ngIf="!isAdmin" class="mandatory">*</span></nz-form-label
            >
            <nz-form-control nzHasFeedback>
              <nz-input-group>
                <input
                  id="cpf"
                  type="text"
                  nz-input
                  formControlName="cpf"
                  mask="000.000.000-00"
                  autocomplete="disabled"
                />
              </nz-input-group>
              <nz-form-explain *ngIf="form.get('cpf').dirty && form.get('cpf').errors">
                <ng-container *ngIf="form.get('cpf').hasError('required')">
                  O CPF é obrigatório
                </ng-container>
                <ng-container
                  *ngIf="!form.get('cpf').hasError('required') && form.get('cpf').hasError('cpf')"
                >
                  CPF inválido, por favor digite um CPF válido.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item
            *ngIf="userTypeLogged !== 'pharmacy' && form.get('dateOfBirth') as dateOfBirth"
            class="col-md"
          >
            <nz-form-label nzFor="dateOfBirth"
              >Data de nascimento <span *ngIf="!isAdmin" class="mandatory">*</span>
            </nz-form-label>
            <nz-form-control nzHasFeedback>
              <input
                nz-input
                id="dateOfBirth"
                type="date"
                formControlName="dateOfBirth"
                [max]="currentDate"
                autocomplete="disabled"
              />

              <nz-form-explain *ngIf="dateOfBirth.dirty && dateOfBirth.errors">
                <ng-container *ngIf="dateOfBirth.hasError('required')">
                  Campo obrigatório, por favor digite sua data de nascimento.
                </ng-container>
                <ng-container
                  *ngIf="!dateOfBirth.hasError('required') && dateOfBirth.hasError('dateOfBirth')"
                >
                  Data de nascimento inválida.
                </ng-container>
              </nz-form-explain>
            </nz-form-control>
          </nz-form-item>
        </div>
      </ng-container>
    </form>

    <div class="row">
      <div class="col-8"></div>

      <div class="col-4">
        <button
          nz-button
          type="button"
          class="btn btn-icon btn-ok text-capitalize"
          (click)="submitForm()"
          [nzLoading]="loading"
        >
          <span class="btn-inner--text">Salvar</span>
        </button>
      </div>
    </div>
  </div>
</div>
