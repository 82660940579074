<div class="modal-header" theme>
  <h4 class="modal-title pull-left text-white" *ngIf="addPatient; else templateTitleSearch">
    Adicionar paciente <span *ngIf="typePatientSelect === enumTypePatient.MINOR">menor</span>
  </h4>
  <ng-template #templateTitleSearch>
    <h4 class="modal-title pull-left text-white">
      Buscar paciente
    </h4>
  </ng-template>

  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div theme *ngIf="typePatientSelect || isVeterinarian; else templateSelectTypePatient">
  <form [formGroup]="form" (submit)="search()">
    <div class="modal-body">
      <p>
        Digite abaixo o CPF do
        <span [ngClass]="{ 'red-bold': noCpf || isVeterinarian }">{{
          noCpf || isVeterinarian ? 'responsável' : 'paciente'
        }}</span
        >.
      </p>
      <div style="width: calc(100% - 50px);" class="mb-3">
        <div class="d-flex flex-column">
          <div
            class="d-flex align-items-start"
            [ngClass]="
              !noCpf && typePatientSelect === enumTypePatient.MINOR
                ? 'justify-content-around'
                : 'justify-content-center'
            "
          >
            <div class="d-flex flex-column">
              <nz-form-item>
                <nz-form-label nzFor="cpf">CPF <span class="mandatory"> *</span></nz-form-label>
                <nz-form-control nzHasFeedback>
                  <input
                    nz-input
                    id="cpf"
                    formControlName="cpf"
                    placeholder="000.000.000-00"
                    mask="000.000.000-00"
                    autocomplete="disabled"
                  />
                </nz-form-control>
              </nz-form-item>
              <label
                *ngIf="addPatient && typePatientSelect === enumTypePatient.MINOR"
                nz-checkbox
                class="mb-3"
                formControlName="noCpf"
                >Menor sem CPF?</label
              >
            </div>
            <nz-form-item
              *ngIf="typePatientSelect === enumTypePatient.MINOR && !this.form.get('noCpf').value"
            >
              <nz-form-label nzFor="dateOfBirth"
                >Data de nascimento<span class="mandatory"> *</span></nz-form-label
              >
              <nz-form-control nzHasFeedback>
                <input
                  nz-input
                  id="dateOfBirth"
                  type="date"
                  formControlName="dateOfBirth"
                  placeholder="DD/MM/AAAA"
                  autocomplete="disabled"
                />
              </nz-form-control>
            </nz-form-item>
            <button
              class="ok-btn btn-salvar"
              [ngClass]="{ 'ml-3': noCpf || typePatientSelect === enumTypePatient.ADULT }"
              nz-button
              type="submit"
              theme
            >
              Pesquisar <i class="fa"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template #templateSelectTypePatient>
  <div class="modal-body">
    <p>O Paciente é:</p>
    <div
      style="
        display: flex;
        justify-content: space-around;
        margin-bottom: 20px;
        width: calc(100% - 150px);
      "
    >
      <button
        nz-button
        theme
        class="btn-select-type-patient"
        (click)="changeTypePatient(enumTypePatient.ADULT)"
      >
        Adulto
      </button>
      <button
        nz-button
        theme
        class="btn-select-type-patient"
        (click)="changeTypePatient(enumTypePatient.MINOR)"
      >
        Menor
      </button>
    </div>
  </div>
</ng-template>
